import {makeAutoObservable} from "mobx";
import {api, catchApi, SetUserOrganizationGroupEnum, UserOrganizationGroupDto} from "../api";

class UserOrganizationRoleStore {
    get data(): UserOrganizationGroupDto | undefined {
        return this._data;
    }

    set data(value: UserOrganizationGroupDto | undefined) {
        this._data = value;
    }

    get userId(): string | undefined {
        return this._userId;
    }

    set userId(value: string | undefined) {
        this._userId = value;
    }

    constructor() {
        makeAutoObservable(this)
    }

    private _userId: string | undefined = undefined
    private _data: UserOrganizationGroupDto | undefined = undefined;

    organizationRole(organizationId: string): SetUserOrganizationGroupEnum | undefined {
        const r = this.data?.organizationRole[organizationId]
        return (r as  any) as SetUserOrganizationGroupEnum
    }

    async setUser(userId: any) {
        if (userId) {
            this.userId = userId as string
            await this.loadUserOrganizationRoles()
        } else {
            this.userId = undefined
        }
    }

    async setUserOrganizationRole(organizationId: string, role: SetUserOrganizationGroupEnum | undefined) {
        await catchApi(async () => {
            if (role) {
                await api.v1.setUserOrganizationGroup(this.userId!!, organizationId, role)
            } else {
                await api.v1.deleteUserOrganizationGroup(this.userId!!, organizationId)
            }
        })
    }

    async loadUserOrganizationRoles() {
        const {userId} = this
        if (!userId) {
            return
        }
        await catchApi(async () => {
            const response = await api.v1.getUserGroupsByOrganization(userId)
            this.data = response.data
        })
    }
}

const userOrganizationRoleStore = new UserOrganizationRoleStore()

export {
    userOrganizationRoleStore
}
