import {observer} from "mobx-react";
import {Organization} from "../../../store";

import "./OrganizationListElement.scss"
import {useNavigate} from "react-router-dom";

interface OrganizationListElementProps {
    organization: Organization
}

const OrganizationListElement = observer(function OrganizationListElement({organization}: OrganizationListElementProps) {
    const navigate = useNavigate()
    let linkTarget = `/organization/branch/${organization.id}`;
    return <div className={"organization-list-element"}>
        <div className={"name"}><a href={linkTarget}
                                   onClick={event => {
                                       event.preventDefault()
                                       event.stopPropagation()
                                       navigate(linkTarget)
                                   }}>{organization.data.name}</a></div>
        {organization.children.length > 0 ?
            <div className={"info"}>
                <span className="tag is-info">{organization.children.length} Standorte</span>
            </div> : <></>}
    </div>
})

export {
    OrganizationListElement
}
