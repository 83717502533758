import {LicensePlate, LicensePlateData} from "./LicensePlate";
import React, {FocusEventHandler} from "react";

interface FieldLicensePlateProps {
    required?: boolean
    disabled?: boolean
    grayscale?: boolean
    label?: string
    value: LicensePlateData
    invalid?: boolean
    onBlur?: FocusEventHandler<any> | undefined
    help?: string
    onChange: (value: LicensePlateData) => void
}

export function FieldLicensePlate({
    invalid,
    disabled,
    value,
    onChange,
    onBlur,
    grayscale,
    help
                                  }: FieldLicensePlateProps) {
    return <>
        <div className={`field`}>
            <div style={{paddingBottom: '0.5em'}}>
                <label className={"checkbox"} style={{userSelect: "none", paddingRight: '1em'}} unselectable={"on"}>
                    <input onBlur={onBlur} disabled={disabled} checked={value.addition === 'E'} onChange={e => {
                        onChange({
                            ...value,
                            addition: e.currentTarget.checked ? 'E' : undefined
                        })
                    }} type={"checkbox"}/>
                    E-Kennzeichen
                </label>
                <label className={"checkbox"} style={{userSelect: "none"}} unselectable={"on"}>
                    <input onBlur={onBlur} disabled={disabled} checked={value.season !== undefined} onChange={e => {
                        const season = e.currentTarget.checked ? { start: 4, end: 9}: undefined
                        onChange({
                            ...value,
                            season: season
                        })
                    }} type={"checkbox"}/>
                    Saisonkennzeichen
                </label>
            </div>
            <div className="control" style={{display: 'flex'}}>
                <LicensePlate onBlur={onBlur} grayscale={grayscale} disabled={disabled} value={value} onChange={onChange} />
            </div>
            <p className={`help ${invalid ? 'is-danger' : ''}`}>{help}</p>
        </div>
    </>
}
