import React, {FocusEventHandler, useEffect, useState} from "react";
import {debounce} from "../../utils";

import "./FieldAutoComplete.scss"

interface FieldAutoCompleteProps<T> {
    id?: string
    required?: boolean
    disabled?: boolean
    label?: string
    value?: T
    invalid?: boolean
    help?: string
    placeholder?: string
    onChange?: (value: T | undefined) => void
    onBlur?: FocusEventHandler<any> | undefined
    search?: (v: string) => Promise<T[]>
    itemRenderer?: (v: T) => React.ReactNode | string
    options?: T[]
}

function FieldAutoComplete<T>({
                                  id,
                                  required,
                                  disabled,
                                  label,
                                  value,
                                  invalid,
                                  help,
                                  placeholder,
                                  onChange,
                                  onBlur,
                                  options,
                                  search,
                                  itemRenderer
                              }: FieldAutoCompleteProps<T>) {
    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(false)

    const [selectOptions, setSelectOptions] = useState<T[]>(options || [])
    useEffect(() => {
        setSelectOptions(options || [])
    }, [options]);

    async function invokeSearch(value: string | undefined) {
        if (value) {
            if (search && value.length > 2) {
                setLoading(true)
                const result = await search(value)
                setLoading(false)
                setSelectOptions(result)
            }
        } else {
            setSelectOptions(options || [])
        }
    }

    return <>
        <div id={id} className={`field-auto-complete field ${required ? 'required' : ''}`}>
            {label && <label className={`label`}>{label}</label>}
            <div className="control">
                <div className={`dropdown ${active && selectOptions.length ? 'is-active' : ''}`}>
                    <div className="dropdown-trigger">
                        <div className={"control has-icons-right"}>
                            {value
                                ? <>
                                    <div className="input"
                                         title={itemRenderer ? itemRenderer(value) as string : ''}>{itemRenderer ? itemRenderer(value) : <>{value}</>}</div>
                                    <span onClick={() => {
                                        onChange && !disabled && onChange(undefined)
                                    }} className="icon is-small is-right"
                                          style={{cursor: 'pointer', pointerEvents: "all"}}>
                                <span className="material-symbols-outlined">cancel</span>
                            </span>
                                </>
                                :
                                <>
                                    <input
                                        id={id?`${id}-input`:undefined}
                                        disabled={disabled}
                                        className={`input is-fullwidth ${invalid ? 'is-danger' : ''}`}
                                        type="text"
                                        placeholder={placeholder}
                                        onChange={debounce(async (e) => {
                                            await invokeSearch(e.target.value)
                                        }, 500)}
                                        onFocus={() => {
                                            setActive(true)
                                        }}
                                        onBlur={e => {
                                            setTimeout(() => {
                                                setActive(false)
                                            }, 200);
                                            onBlur && onBlur(e)
                                        }}
                                    />{loading ?
                                    <span className="icon is-small is-right">
                            <span className={"button is-loading"} style={{border: "none"}}></span>
                        </span> : <></>}
                                </>
                            }
                        </div>
                    </div>
                    <div className="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                            {selectOptions.map((o, index) => {
                                return <button key={index} id={id?`${id}-button-${index}`:undefined} className="dropdown-item"
                                               title={itemRenderer ? itemRenderer(o) as string : ''} onClick={() => {
                                    onChange && onChange(o)
                                }}>{itemRenderer ? itemRenderer(o) : <>{o}</>}</button>
                            })}
                        </div>
                    </div>
                </div>


            </div>
            <p className={`help ${invalid ? 'is-danger' : ''}`}>{help}</p>
        </div>


    </>
}

export {
    FieldAutoComplete
}
