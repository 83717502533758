import {observer} from "mobx-react";
import React from "react";
import {registrationStore} from "../../../../store";
import {CitySelect, FieldInput} from "../../../ui";
import {registrationValidationStore} from "../../../../store/RegistrationValidationStore";

function formatIban(val: string | undefined) {
    return (val || '').replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ').trim()
}

const BankDataPanel = observer(function BankDataPanel() {
    const value = registrationStore.bankData

    const displayData = registrationStore.bankDisplayData

    const deviatingTaxPayerHelp = registrationValidationStore.getFor("deviatingTaxPayer.name")

    return <>
        <nav className="panel">
            <p className="panel-heading">Bankdaten</p>
            <div className="panel-block">
                <div style={{paddingTop: '0.5em'}}>
                    <div className={`field required`}>
                        <label className="label">IBAN</label>
                        <div className={`control has-icons-right`}>
                            <input
                                id={'iban-input'}
                                disabled={registrationStore.isReadonly}
                                onInput={e => {
                                    const val = e.currentTarget.value.toUpperCase()
                                    e.currentTarget.value = formatIban(val)
                                }}
                                onChange={async e => {
                                    const val = e.target.value
                                    const iban = (val && val.length === 27) ? val.replaceAll(' ', '') : undefined
                                    if (!iban) {
                                        if (value?.iban !== iban) {
                                            await registrationStore.setIban(iban)
                                        }
                                    } else {
                                        await registrationStore.setIban(iban)
                                    }
                                }}
                                onBlur={() => registrationStore.validate()}
                                maxLength={27}
                                defaultValue={formatIban(value?.iban)}
                                className={`input ${displayData?.valid ? '' : 'is-danger'}`}
                                type="text"
                            />
                            {registrationStore.validatingIban
                                ? <span className="icon is-small is-right">
                                    <span className={"button is-loading"} style={{border: "none"}}></span></span>
                                : <></>}
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">BIC</label>
                        <div className="control">
                            <input
                                disabled={registrationStore.isReadonly}
                                value={displayData?.bic || ''}
                                onChange={() => {
                                    // do nothing
                                }}
                                className={`input ${displayData?.valid ? '' : 'is-danger'}`}
                                readOnly={true}
                                type="text"/>
                        </div>
                        <p className={`help ${displayData?.valid ? '' : 'is-danger'}`}>{displayData?.valid ? '' : 'Bitte trage eine gültige IBAN ein'}</p>
                    </div>
                </div>
            </div>
            {displayData?.name ? <div className="panel-block">
                <div>
                    <div className="columns">
                        <div className="column is-half">
                            <label className={"label is-small"}>Bankleitzahl</label>
                            <span className={"is-size-7"}>{displayData?.bankCode}</span>
                        </div>
                        <div className="column is-half">
                            <label className={"label is-small"}>Kontonummer</label>
                            <span className={"is-size-7"}>{displayData?.accountNumber}</span>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-half">
                            <label className={"label is-small"}>Bank</label>
                            <span className={"is-size-7"}>{displayData?.name}</span>
                        </div>
                        <div className="column is-half">
                            <label className={"label is-small"}>BIC</label>
                            <span className={"is-size-7"}>{displayData?.bic}</span>
                        </div>
                    </div>
                </div>
            </div> : <></>}
            <div className={"panel-block"}>
                <div>
                    <label className={"checkbox"}>
                        <input disabled={registrationStore.isReadonly} type={"checkbox"} checked={Boolean(registrationStore.data?.deviatingTaxPayer)}
                               onChange={e => {
                                   registrationStore.setInitialDeviatingTaxPayer(e.target.checked)
                               }}/>
                        Abweichender Zahler Kfz-Steuer
                    </label>

                    {registrationStore.data?.deviatingTaxPayer ? <div style={{paddingTop: "1em"}}>
                        <FieldInput
                            label={"Name"}
                            placeholder={"Kontoinhaber"}
                            value={registrationStore.deviatingTaxPayerName}
                            onChange={v => registrationStore.deviatingTaxPayerName = v}
                            required={true}
                            onBlur={() => registrationStore.validate()}
                            help={deviatingTaxPayerHelp}
                            disabled={registrationStore.isReadonly}
                            invalid={Boolean(deviatingTaxPayerHelp)}
                        />

                        <div className={"columns"}>
                            <div className={"column is-half"}>
                                <FieldInput
                                    label={"Straße"}
                                    value={registrationStore.deviatingTaxPayerStreet}
                                    onChange={v => {
                                        registrationStore.deviatingTaxPayerStreet = v
                                    }}
                                    onBlur={() => registrationStore.validate()}
                                    disabled={registrationStore.isReadonly}
                                />
                            </div>
                            <div className={"column is-one-quarter"}>
                                <FieldInput
                                    label={"Hausnummer"}
                                    value={registrationStore.deviatingTaxPayerHouseNumber}
                                    onChange={v => {
                                        registrationStore.deviatingTaxPayerHouseNumber = v
                                    }}
                                    onBlur={() => registrationStore.validate()}
                                    disabled={registrationStore.isReadonly}
                                />
                            </div>
                            <div className={"column is-one-quarter"}>
                                <FieldInput
                                    label={"Hausnummer Zusatz"}
                                    value={registrationStore.deviatingTaxPayerAddressAddition}
                                    onChange={v => {
                                        registrationStore.deviatingTaxPayerAddressAddition = v
                                    }}
                                    onBlur={() => registrationStore.validate()}
                                    disabled={registrationStore.isReadonly}
                                />
                            </div>
                        </div>

                        <CitySelect
                            onChange={e => {
                                registrationStore.deviatingTaxPayerPostCode = e?.zip || ''
                                registrationStore.deviatingTaxPayerCity = e?.city || ''
                            }}
                            onBlur={() => registrationStore.validate()}
                            city={registrationStore.deviatingTaxPayerCity}
                            zip={registrationStore.deviatingTaxPayerPostCode}
                        />
                    </div> : <></>}
                </div>
            </div>
        </nav>
    </>
})

export {
    BankDataPanel
}
