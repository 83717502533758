import {DialogStore} from "../../../../../store";
import {SubmitRegistrationDto} from "../../../../../api";

export interface ConfirmationProps {
    dialogStore: DialogStore,
    value: SubmitRegistrationDto
    onChange: (dto: SubmitRegistrationDto) => void
}

export const confirmationFormTextZBII = "Sie können nun die Zulassung beantragen.\n" +
    "carTRUST übermittelt alle notwendigen Daten an die Zulassungsstelle.\n" +
    "\n" +
    "Legen Sie dafür den Sicherheitscode auf ihrem ZBII-Dokument frei.\n" +
    "Beachten Sie, dass die ZBII nach Freilegen des Sicherheitscode ungültig wird!"

export const confirmationFormTextZBI = "Sie können nun die Zulassung beantragen.\n" +
    "carTRUST übermittelt alle notwendigen Daten an die Zulassungsstelle.\n" +
    "\n" +
    "Legen Sie dafür den Sicherheitscode auf ihrem ZBI-Dokument frei.\n" +
    "Beachten Sie, dass die ZBI nach Freilegen des Sicherheitscode ungültig wird!"

export const securityCodePattern = /^B[0-9A-Za-z]{10}[0-9X]$/g
export const verificationCodePattern = /^[0-9A-Za-z]{7}$/g
export const sealCodePattern = /^[0-9A-Za-z]{3}$/g

export function isValid(securityCode: string | undefined, pattern: RegExp) {
    if (!securityCode) {
        return false
    }
    return Boolean(securityCode?.match(pattern))
}
