import {observer} from "mobx-react";
import {apiStatus} from "../../api";
import {string} from "yup";

const ApiErrorDialog = observer(function ApiErrorDialog() {
    const data = apiStatus.error?.data
    let title = apiStatus.error?.statusText
    if (!data && !title) {
        return <></>
    }
    let message = ''
    if (data) {
        if (data.error) {
            title = data.error
        }
        if (data instanceof string) {
            message = data as any as string
        } else if (data.message) {
            message = data.message
        } else {
            message = JSON.stringify(data)
        }


    }
    return <>
        <div className={`modal is-active`}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <article className="message is-danger">
                    <div className="message-header">
                        <p>{title}</p>
                        <button className="delete" aria-label="delete" onClick={() => apiStatus.clearErrors()}></button>
                    </div>
                    <div className="message-body">
                        <div style={{
                            overflow: 'auto',
                            padding: '0.1em',
                            fontFamily: 'monospace',
                            backgroundColor: 'var(--ct-primary-font-color)',
                            color: 'var(--ct-background-variant-color)'
                        }}>{message}</div>
                    </div>
                </article>
            </div>
        </div>
    </>
})

export {
    ApiErrorDialog
}
