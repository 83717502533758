import React, {FocusEventHandler} from "react";

interface FieldDateProps {
    id?: string
    required?: boolean
    label?: string
    value?: string | Date
    invalid?: boolean
    disabled?: boolean
    help?: string
    placeholder?: string
    onBlur?: FocusEventHandler<any> | undefined
    onChange?: (value: string) => void
}

function FieldDate({
                       id,
                       required,
                       label,
                       value,
                       invalid,
                       disabled,
                       help,
                       placeholder,
                       onBlur,
                       onChange,
                   }: FieldDateProps) {
    const v = value ? isNaN(new Date(value).valueOf()) ? '' : (new Date(value)).toISOString().substring(0, 10) : ''
    return <>
        <div id={id} className={`field ${required ? 'required' : ''}`}>
            {label && <label className={`label`}>{label}</label>}
            <div className="control">
                <input
                    id={id?`${id}-input`:undefined}
                    disabled={disabled}
                    className={`input is-fullwidth ${invalid ? 'is-danger' : ''} ${required ? 'required' : ''}`}
                    type={"date"}
                    value={v}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onChange={e => onChange && onChange(e.target.value)}
                />
            </div>
            <p className={`help ${invalid ? 'is-danger' : ''}`}>{help}</p>
        </div>
    </>
}

export {
    FieldDate
}
