import {observer} from "mobx-react";
import {AppHeader} from "../../app";
import {DialogStore, UserCreateStore, userListStore} from "../../../store";
import {Dialog, Pager} from "../../ui";
import {UserCreateForm} from "./UserCreateForm";
import {useState} from "react";
import {UserTable} from "./UserTable";
import {SearchInputField} from "../../ui/SearchInputField";


const UserOverviewPage = observer(function UserOverviewPage() {
    const [createStore] = useState(new UserCreateStore())
    const [dialogStore] = useState(new DialogStore())

    function openCreateDialog() {
        createStore.reset()
        dialogStore.modalStore.open()
    }

    const {totalPages} = userListStore

    return <>
        <AppHeader title={"Benutzer"}>
            <SearchInputField></SearchInputField>
            <button className={"button is-primary"} onClick={() => {
                openCreateDialog()
            }}>Benutzer anlegen
            </button>
        </AppHeader>
        <UserTable/>
        <Pager total={totalPages}></Pager>
        <Dialog store={dialogStore} title={<>
            <span className="material-symbols-outlined"
                  style={{color: "var(--bulma-primary)", marginRight: "0.25em"}}>group</span> Benutzer anlegen
        </>} primaryAction={async () => {
            await createStore.submit()
            await userListStore.load()
            return true
        }} primaryActionTitle={"Anlegen"}>
            <UserCreateForm store={createStore}/>
        </Dialog>
    </>
})

export {
    UserOverviewPage
}
