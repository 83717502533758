import {makeAutoObservable} from "mobx";

class PageLoadingStore {
    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    private _loading: boolean = false

    constructor() {
        makeAutoObservable(this)
    }
}

const pageLoadingStore = new PageLoadingStore()

export {
    pageLoadingStore
}
