import {observer} from "mobx-react";
import {Organization} from "../../../store";
import {OrganizationListElement} from "./OrganizationListElement";

import "./OrganizationListGroup.scss"
import {ReactNode, useState} from "react";
import {OrganizationList} from "./OrganizationList";

interface OrganizationListGroupProps {
    organization: Organization,
    expanded?: boolean,
    itemRenderer?: (organization: Organization) => ReactNode
}

function getExpandIcon(collapsed: boolean) {
    return collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
}

const OrganizationListGroup = observer(function OrganizationListGroup({organization, expanded, itemRenderer}: OrganizationListGroupProps) {

    const [collapsed, setCollapsed] = useState(!expanded)

    const expandable = organization.children.length > 0
    return <div className={`organization-list-group ${collapsed?"collapsed":"expanded"}`}>
        <div className={`element ${ expandable? 'expandable' : ''}`} onClick={() => {setCollapsed(!collapsed)}}>
            {itemRenderer?itemRenderer(organization):<OrganizationListElement organization={organization}/>}
            {expandable &&<div className={"expander"}>
                <span className="material-symbols-outlined">{getExpandIcon(collapsed)}</span>
            </div>}
        </div>
        {expandable && !collapsed &&<div className={"list"}>
            <OrganizationList elements={organization.children} itemRenderer={itemRenderer} expanded={expanded} />
        </div>}

    </div>
})

export {
    OrganizationListGroup
}
