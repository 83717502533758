import {registrationValidationStore} from "../../../../store/RegistrationValidationStore";
import {registrationStore, RegistrationStoreProp} from "../../../../store";
import {observer} from "mobx-react";
import {FieldLicensePlate} from "../../../ui/FieldLicensePlate";
import {LicensePlateData} from "../../../ui/LicensePlate";


const RegistrationLicensePlateField = observer(function RegistrationLicensePlateField({disabled, licensePlateField}: {
    disabled?: boolean
    licensePlateField: RegistrationStoreProp
}) {
    const licensePlateHelp = [
        registrationValidationStore.getFor(`${licensePlateField}`),
        registrationValidationStore.getFor(`${licensePlateField}.distinguishingSign`),
        registrationValidationStore.getFor(`${licensePlateField}.identification`),
        registrationValidationStore.getFor(`${licensePlateField}.identificationNumber`),
        registrationValidationStore.getFor(`${licensePlateField}.seasonStart`),
        registrationValidationStore.getFor(`${licensePlateField}.seasonEnd`)]
        .filter(i => Boolean(i)).length > 0 ? "1 - 3 Buchstaben & 1 - 2 Buchstaben & 1 - 4 stellige Zahl" : undefined

    return <FieldLicensePlate
        disabled={disabled !== undefined ? disabled : registrationStore.isReadonly}
        invalid={Boolean(licensePlateHelp)}
        help={licensePlateHelp}
        value={registrationStore[licensePlateField] as LicensePlateData}
        onBlur={() => registrationStore.validate()}
        onChange={
            // @ts-ignore
            v => registrationStore[licensePlateField] = v
        }/>
})

export {
    RegistrationLicensePlateField
}
