import {observer} from "mobx-react";
import {AppHeader} from "../../../app";
import {SearchInputField} from "../../../ui/SearchInputField";
import {RegistrationOverviewFilter} from "./RegistrationOverviewFilter";
import {Dialog, Growl, Pager} from "../../../ui";
import {RegistrationOverviewList} from "./RegistrationOverviewList";
import {
    authStore,
    createRegistrationStore,
    DialogStore,
    featureStore,
    growlStore,
    registrationOverviewFilterStore,
    registrationOverviewStore
} from "../../../../store";

import "./RegistrationOverviewPage.scss"
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {CreateRegistrationForm} from "../detail/CreateRegistrationForm";
import {orderType,} from "../../../../api";
import {registrationExportStore} from "../../../../store/RegistrationExportStore";
import {saveData} from "../../../../utils";


function isFiltered(params: URLSearchParams): string {
    return params.get("types")
    || params.get("status")
    || params.get("from")
    || params.get("to")
    || params.get("archived") !== null
    || params.get("organisationId")
        ? "filter-is-active"
        : ""
}



const CreateRegistrationDialog = observer(function CreateRegistrationDialog({dialogStore}: { dialogStore: DialogStore }) {
    const navigate = useNavigate()
    const serviceType = createRegistrationStore.serviceType
    const organization = createRegistrationStore.organization
    const ready = createRegistrationStore.ready

    useEffect(() => {
        dialogStore.primaryReady = ready
    }, [ready, dialogStore])

    return <>
        <Dialog store={dialogStore} title={"Neuen Vorgang anlegen"} titleCloseable={false}
                id={'create-registration-dialog'}
                primaryAction={async () => {
                    // store.close()
                    // navigate
                    dialogStore.primaryExecuting = true
                    const reg = await createRegistrationStore.save()
                    dialogStore.primaryExecuting = false
                    if (reg) {
                        navigate(`/registration/${reg.id}`)
                        return true
                    }
                    return null
                }}
                primaryActionTitle={`${orderType(createRegistrationStore.serviceType)} anlegen`}>
            <CreateRegistrationForm
                serviceType={serviceType}
                organization={organization}
                onChange={v => {
                    createRegistrationStore.serviceType = v.serviceType
                    createRegistrationStore.organization = v.organization
                    dialogStore.primaryReady = createRegistrationStore.ready
                }}
            />
        </Dialog>
    </>
})

const exportCsv = async function (){
    const exportFinished = registrationExportStore.prepareExport();
    await registrationExportStore.startExport()
    await exportFinished
    growlStore.addMessage({
        message: 'Ihr Export ist bereit',
        action: async () => {
            const blob = await registrationExportStore.downloadDocument()
            if (blob) {
                saveData(blob, "Zulassungen-Export.csv")
            }
        },
        actionLabel: "Herunterladen"
    })
}

const RegistrationOverviewPage = observer(function RegistrationOverviewPage() {
    const [createDialogStore] = useState(new DialogStore())
    const [searchParams, ] = useSearchParams()
    const isFilteredCSS = isFiltered(searchParams)
    return <>
        <AppHeader title={"Übersicht"}>
            <SearchInputField></SearchInputField>
            <button className={`button is-light compact-button ${isFilteredCSS}`}
                    title={registrationOverviewFilterStore.visible ? "Filterpanel schließen" : "Filterpanel öffnen"}
                    onClick={() => {
                        registrationOverviewFilterStore.visible = !registrationOverviewFilterStore.visible
                    }}>
                <span className="material-symbols-outlined">tune</span>
            </button>
            {featureStore.isActive('REGISTRATION_EXPORT') && authStore.isAdmin &&
                <button
                    className={`button is-light compact-button ${registrationExportStore.exportRunning ? 'is-loading' : ''}`}
                    title={"Export als CSV"} onClick={exportCsv}>
                    <span className="material-symbols-outlined">download</span>
                </button>}
            <span style={{marginRight: '1em'}}></span>
            <button id={"start-registration-button"} className={`button is-primary`}
                    onClick={() => {
                        createRegistrationStore.reset()
                        createDialogStore.open()
                    }}>Neuer Vorgang
            </button>
        </AppHeader>
        <div className={"registration-overview-page"}>
            <div className={`filter ${registrationOverviewFilterStore.visible ? "expanded" : ""}`}>
                <RegistrationOverviewFilter/>
            </div>
            <div className={"registration-overview-list"}>
                <RegistrationOverviewList/>
            </div>
        </div>
        <Pager total={registrationOverviewStore.totalPages}/>
        <CreateRegistrationDialog dialogStore={createDialogStore} />
        <Growl/>
    </>
})

export {
    RegistrationOverviewPage
}
