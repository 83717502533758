import {UserDto} from "../../../api";
import {observer} from "mobx-react";

import "./UserDetailTab.scss"

const UserDetailTab = observer(function UserDetailTab({user}: { user: UserDto }) {
    return <div className={"user-detail-tab"}>
        <div>
            <label className={"label"}>Id:</label><span>{user.id}</span>
        </div>
        <div>
            <label className={"label"}>Benutzername:</label><span>{user.username}</span>
        </div>
        <div>
            <label className={"label"}>E-Mail:</label><span>{user.email}</span>
        </div>
        <div>
            <label className={"label"}>Vorname:</label><span>{user.firstName}</span>
        </div>
        <div>
            <label className={"label"}>Nachname:</label><span>{user.lastName}</span>
        </div>
        <div>
            <label className={"label"}>Keycloak-Id:</label><span>{user.keycloak}</span>
        </div>
    </div>
})

export {
    UserDetailTab
}
