import {makeAutoObservable} from "mobx";
import {api, catchApi, UserDto} from "../api";

//UserListStore
class UserListStore {
    get users(): UserDto[] {
        return this._users;
    }

    set users(value: UserDto[]) {
        this._users = value;
    }

    get totalPages(): number {
        return this._totalPages;
    }

    set totalPages(value: number) {
        this._totalPages = value;
    }

    get search(): string {
        return this._search;
    }

    set search(value: string) {
        this._search = value;
    }

    get page(): number {
        return this._page;
    }

    set page(value: number) {
        this._page = value;
    }

    get size(): number {
        return this._size;
    }

    set size(value: number) {
        this._size = value;
    }


    constructor() {
        makeAutoObservable(this)
    }

    private _users: UserDto[] = []
    private _totalPages: number = 0

    private _search: string = ""
    private _page: number = 1
    private _size: number = 25


    async load() {
        const {search, page, size} = this
        await catchApi(async () => {
            const response = await api.v1.listUser({
                page: page -1,
                search,
                size
            })
            this.users = response.data.items
            this.totalPages = response.data.totalPages
        })
    }
}

const userListStore = new UserListStore()

export {
    userListStore
}
