import {observer} from "mobx-react";
import {Navbar} from "../ui";
import {useParams} from "react-router-dom";
import {RegistrationDetailPage, RegistrationOverviewPage} from "../page/registration";

const RegistrationApp = observer(function RegistrationApp() {
    const params = useParams()
    return <div className={"app-wrapper"}>
        <Navbar />
        {params.id?<RegistrationDetailPage />:<RegistrationOverviewPage />}
    </div>
})

export {
    RegistrationApp
}
