import {observer} from "mobx-react";
import {useEffect} from "react";
import {registrationStore} from "../../../../../../store";
import {FieldInput} from "../../../../../ui";
import {confirmationFormTextZBII, ConfirmationProps, isValid, securityCodePattern} from "../../confirmation/ConfirmationFormCommons";

const ConfirmationFormNz = observer(function ConfirmationFormNz({
                                                                    dialogStore,
                                                                    value,
                                                                    onChange
                                                                }: ConfirmationProps) {
    const invalid = !isValid(value.securityCode, securityCodePattern)

    useEffect(() => {
        dialogStore.primaryReady = isValid(value.securityCode, securityCodePattern)
    }, [value, dialogStore]);

    return <>
        <div style={{
            whiteSpace: 'pre-line'
        }}>
            {confirmationFormTextZBII}
        </div>
        <div style={{
            paddingTop: '1em',
            paddingBottom: '1em'
        }}>
            <label className={"label"}>Fahrzeug: {registrationStore.vin}</label>
        </div>
        <div className={"columns"}>
            <div className={"column is-half"}>
                <FieldInput
                    label={"ZBII Sicherheitscode"}
                    id={'security-code'}
                    value={value.securityCode}
                    onChange={v => {
                        onChange({...value, securityCode: v})
                    }}
                    invalid={invalid}
                    required={true}
                    disabled={dialogStore.primaryExecuting}
                    maxLength={12}
                    help={!value.securityCode ? 'Pflichtfeld' : `B gefolgt von 11 Zahlen-Buchstabenkombination endend mit X oder Zahl`}
                />
            </div>
        </div>
    </>
})

export {
    ConfirmationFormNz
}
