import {makeAutoObservable} from "mobx";
import {api, catchApi} from "../api";

interface Organization {
    id: string;
    name: string;
    parent?: string;
    version: number;
    costCenter?: string;
}

class OrganizationStore {
    get emptyOrganization(): Organization {
        return this._emptyOrganization;
    }

    set emptyOrganization(value: Organization) {
        this._emptyOrganization = value;
    }

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    get organizationId(): string | undefined {
        return this._organizationId;
    }

    set organizationId(value: string | undefined) {
        if (!value) {
            this.resetEmptyOrganization()
        }

        this._organizationId = value;
    }

    get tenantOrganizations(): Organization[] {
        return this._tenantOrganizations;
    }

    set tenantOrganizations(value: Organization[]) {
        this._tenantOrganizations = value;
    }

    get tenantOrganizationParentOptions(): Organization[] {
        return this.tenantOrganizations.filter(it => it.id !== this.organizationId);
    }

    get tenantOrganization(): Organization {
        let defaultOrganization = {id: "", name: "", version: 0};
        if (this.organizationId) {
            return this.tenantOrganizations.filter(it => it.id === this.organizationId)[0] || defaultOrganization
        } else {
            return this.emptyOrganization
        }
    }

    get costCenter(): string {
        return this.tenantOrganization.costCenter || "";
    }

    set costCenter(value: string) {
        this.tenantOrganization.costCenter = value === "" ? undefined : value;
    }

    get parent(): string {
        return this.tenantOrganization.parent || "";
    }

    set parent(value: string) {
        this.tenantOrganization.parent = value === "" ? undefined : value;
    }

    get name(): string {
        return this.tenantOrganization.name;
    }

    set name(value: string) {
        this.tenantOrganization.name = value;
    }

    constructor() {
        makeAutoObservable(this)
    }

    private _tenantOrganizations: Organization [] = []
    private _organizationId: string | undefined
    private _loading: boolean = false
    private _emptyOrganization: Organization = {id: "", name: "", version: 0,}

    private resetEmptyOrganization() {
        this.emptyOrganization = {id: "", name: "", version: 0,}
    }

    async load() {
        await catchApi(async () => {
            const response = await api.v1.listOrganizations()
            this.tenantOrganizations = response.data || []
        })
    }

    async save() {
        const {
            name,
            organizationId,
            parent,
            tenantOrganization,
            costCenter,
            loading} = this
        const createData = {
            name,
            parent,
            costCenter
        }
        if (loading) {
            return
        }
        this.loading = true
        if (organizationId) {
            return await catchApi(async () => {
                return await api.v1.updateOrganization({
                    id: organizationId!!,
                    version: tenantOrganization.version,
                    ...createData
                })
            }).finally(() => {
                this.loading = false
            })
        }
        return await catchApi(async () => {
            return await api.v1.createOrganization(createData)
        }).finally(() => {
            this.resetEmptyOrganization()
            this.loading = false
        })
    }
}

const organizationStore = new OrganizationStore()

export {
    organizationStore
}
