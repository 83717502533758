import {observer} from "mobx-react";
import {useAuth} from "react-oidc-context";
import React from "react";
import {RouterProvider} from "react-router-dom";
import {authStore, tenantSelectStore} from "../../store";
import {provider} from "./Router";
import {tenantselection} from "../page";

function NavigateToLogin() {
    const auth = useAuth();
    React.useEffect(() => {
        auth.signinRedirect()
    })
    return <></>
}

const AuthenticatedApp = observer(function AuthenticatedApp() {
    const auth = useAuth();

    React.useEffect(() => {
        authStore.auth = auth
        // the `return` is important - addAccessTokenExpiring() returns a cleanup function
        return auth.events.addAccessTokenExpiring(async () => {
            await auth.signinSilent();
        })
    }, [auth, auth.events, auth.signinSilent]);

    switch (auth.activeNavigator) {
        // case "signinSilent":
        //     console.log('signinSilent');
        //     return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.error) {
        return <NavigateToLogin />;
    }

    if (auth.isAuthenticated || auth.isLoading) {
        if (authStore.accessToken) {
            if (tenantSelectStore.superadmin && !tenantSelectStore.tenant) {
                return <tenantselection.TenantSelectionPage />
            }
            return (
                <RouterProvider router={provider.router}/>
            );
        } else if(auth.isAuthenticated) {
            return <div>waiting...</div>
        } else {
            return <div>loading...</div>
        }

    }
    return <NavigateToLogin />;
})

export {
    AuthenticatedApp
}
