import {makeAutoObservable} from "mobx";
import {ModalStore} from "./ModalStore";

class DialogStore {
    set onClose(value: () => void) {
        this._onClose = value;
    }
    get data(): any {
        return this._data;
    }

    set data(value: any) {
        this._data = value;
    }
    get errorMessage(): string | undefined {
        return this._errorMessage;
    }

    set errorMessage(value: string | undefined) {
        this._errorMessage = value;
    }
    get primaryExecuting(): boolean {
        return this._primaryExecuting;
    }

    set primaryExecuting(value: boolean) {
        this._primaryExecuting = value;
    }
    get primaryReady(): boolean | undefined {
        return this._primaryReady;
    }

    set primaryReady(value: boolean | undefined) {
        this._primaryReady = value;
    }
    get modalStore(): ModalStore {
        return this._modalStore as ModalStore;
    }

    private readonly _modalStore: ModalStore | null = null
    private _primaryReady: boolean | undefined
    private _primaryExecuting: boolean = false
    private _errorMessage: string | undefined
    private _data: any
    private _onClose: () => void

    constructor() {
        makeAutoObservable(this)
        this._onClose = () => {}
        this._modalStore = new ModalStore()
    }

    open() {
        this.modalStore.open()
    }

    close() {
        this.modalStore.close()
        this._onClose()
    }
}

export {
    DialogStore
}
