import {makeAutoObservable} from "mobx";

class RouteStore {
    get active(): string {
        return this._active;
    }

    set active(value: string) {
        this._active = value;
    }

    private _active: string = '';
    constructor() {
        makeAutoObservable(this)
    }
}

const routeStore = new RouteStore()

export {
    routeStore
}
