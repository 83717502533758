import {ConfirmationFormAb} from "../servicetype/AB/ConfirmationFormAb"
import {ConfirmationFormNz} from "../servicetype/NZ/ConfirmationFormNz"
import {ConfirmationFormWz} from "../servicetype/WZ/ConfirmationFormWz"
import {ConfirmationFormUm} from "../servicetype/UM/ConfirmationFormUm"

const ConfirmationFormTz = ConfirmationFormNz
const ConfirmationFormUo = ConfirmationFormUm
const ConfirmationFormUi = ConfirmationFormUm

export {
    ConfirmationFormNz,
    ConfirmationFormWz,
    ConfirmationFormAb,
    ConfirmationFormTz,
    ConfirmationFormUm,
    ConfirmationFormUo,
    ConfirmationFormUi,
}
