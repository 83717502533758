import {observer} from "mobx-react";
import {useSearchParams} from "react-router-dom";
import {debounce} from "../../utils";

const SearchInputField = observer(function SearchInputField() {
    const [searchParams, setSearchParams] = useSearchParams()

    return (
        <div className="control has-icons-right">
            <input
                type="text"
                className="input"
                onChange={debounce( (e) => {
                    const newSearch = e.target.value
                    setSearchParams((sp) => {
                        if (newSearch) {
                            sp.set("search", newSearch)
                        } else {
                            sp.delete("search")
                        }
                        //reset the page so that we jump to page 1
                        sp.delete("page")
                        return sp
                    })
                }, 500)}
                defaultValue={searchParams.get("search") || ""}
                placeholder="Suche"
            />
            <span className="icon is-small is-right">
                <span className="material-symbols-outlined">search</span>
            </span>
        </div>
    )
})

export {
    SearchInputField
}
