import {makeAutoObservable} from "mobx";
import {api, catchApi} from "../api";
import {FormikType} from "./FormikType";

export interface FormType {
    firstname: string;
    email: string;
    username: string;
    lastname: string
}

class UserCreateStore {
    get formik(): FormikType<FormType> | undefined {
        return this._formik;
    }

    set formik(value: FormikType<FormType> | undefined) {
        this._formik = value;
    }
    private _formik?: FormikType<FormType>;

    constructor() {
        makeAutoObservable(this)
    }

    async create() {

    }

    reset() {
        this.formik?.resetForm()
    }

    async submit() {
        if (!this.formik) return false
        await this.formik.submitForm()
        if(!this.formik.isValidating && this.formik.isValid) {
            const values = this.formik.values
            const {
                firstname,
                lastname,
                username,
                email,
            } = values
            await catchApi(async () => {
                await api.v1.createUser({
                    email,
                    lastName: lastname,
                    username,
                    firstName: firstname
                })

                this.reset()
            })
        } else {
            return null
        }
    }
}

export {
    UserCreateStore
}
