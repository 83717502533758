import {observer} from "mobx-react";
import {HolderNaturalForm} from "./HolderNaturalForm";
import {HolderLegalForm} from "./HolderLegalForm";
import {registrationStore} from "../../../../../store";

const HolderPanel = observer(function HolderPanel() {
    return <>
        <nav className="panel">
            <p className="panel-heading">Halter</p>
            <div className="panel-block">
                <div>
                    <div className="tabs" style={{
                        marginLeft: '-1em',
                        marginTop: '-0.75em',
                        marginRight: '-1em',
                    }}>
                        <ul>
                            <li className={`${!registrationStore.isCorporate ? 'is-active' : ''}`}><a href={'/#'} onClick={(e) => {
                                e.preventDefault()
                                registrationStore.isCorporate = false
                            }}>Natürliche Person</a></li>
                            <li className={`${registrationStore.isCorporate ? 'is-active' : ''}`}><a href={'/#'} onClick={(e) => {
                                e.preventDefault()
                                registrationStore.isCorporate = true
                            }}>Juristische Person</a></li>
                        </ul>
                    </div>

                    {!registrationStore.isCorporate && <HolderNaturalForm />}
                    {registrationStore.isCorporate && <HolderLegalForm />}
                </div>
            </div>
        </nav>
    </>
})

export {
    HolderPanel
}
