import {observer} from "mobx-react";
import {JSX, useState} from "react";
import {RegistrationNotificationDto, RegistrationNotificationDtoNotificationTypeEnum} from "../../../../../api";

import "./RegistrationNotificationBar.scss"
import {registrationNotificationStore} from "../../../../../store";

function getIcon(notificationType: RegistrationNotificationDtoNotificationTypeEnum): string | undefined {
    switch (notificationType) {
        case RegistrationNotificationDtoNotificationTypeEnum.INFO: return 'info'
        case RegistrationNotificationDtoNotificationTypeEnum.SUCCESS: return 'check_circle'
        case RegistrationNotificationDtoNotificationTypeEnum.ERROR: return 'cancel'
        case RegistrationNotificationDtoNotificationTypeEnum.WARNING: return 'warning'
    }
    return undefined
}

function RegistrationNotification({
    dto,
    ActionButton
                                  }: {
    dto: RegistrationNotificationDto,
    ActionButton?: () => JSX.Element
}) {
    const icon = getIcon(dto.notificationType)

    return <>{icon ? <>
        <div className={`registration-notification ${dto.notificationType}`}>
            <div className={"icon"}><span className="material-symbols-outlined">{icon}</span></div>
            <div className={"notification-message"} title={dto.notificationMessage}>{dto.notificationMessage}</div>
            {ActionButton ? <div className={"action"}><ActionButton /></div> : <></>}
        </div>
    </> : <></>}
    </>
}

const RegistrationNotificationBar = observer(function RegistrationNotificationBar() {
    const [showSingle, setShowSingle] = useState(true)

    if (!registrationNotificationStore.data || registrationNotificationStore.data.length === 0) {
        return <></>
    }

    if (registrationNotificationStore.data.length === 1) {
        return <RegistrationNotification dto={registrationNotificationStore.data[0]} />
    }
    const ActionButton = <button
        onClick={() => {
            setShowSingle(!showSingle)
        }}
        className={"button is-primary is-small"}>{showSingle ? 'Restliche Meldungen anzeigen' :'Restliche Meldungen ausblenden'}</button>
    if (showSingle) {
        return <RegistrationNotification dto={registrationNotificationStore.data[0]} ActionButton={() => ActionButton} />
    }

    return <>
        {registrationNotificationStore.data.map((e, index) => <RegistrationNotification
            dto={e}
            ActionButton={index === 0 ? () => ActionButton : undefined}
        />)}
    </>
})

export {
    RegistrationNotificationBar
}
