import {makeAutoObservable} from "mobx";
import {api, RegistrationOfficePortalAvailabilityDto, RegistrationOfficePortalAvailabilityDtoStatusEnum} from "../api";

type SubscriberFn = (dto: RegistrationOfficePortalAvailabilityDto) => Promise<void>

class RegistrationOfficePortalAvailabilityStore {
    get lastUpdate(): string | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: string | undefined) {
        this._lastUpdate = value;
    }
    get status(): RegistrationOfficePortalAvailabilityDtoStatusEnum | undefined {
        return this._status;
    }

    set status(value: RegistrationOfficePortalAvailabilityDtoStatusEnum | undefined) {
        this._status = value;
    }
    get correlationId(): string | undefined {
        return this._correlationId;
    }

    set correlationId(value: string | undefined) {
        this._correlationId = value;
    }
    get waiting(): boolean {
        return this._waiting;
    }

    set waiting(value: boolean) {
        this._waiting = value;
    }

    private _status: RegistrationOfficePortalAvailabilityDtoStatusEnum | undefined;
    private _correlationId: string | undefined
    private _waiting: boolean = false
    private _lastUpdate: string | undefined;

    private _subscribedId: string | undefined
    private _subscriberFn: SubscriberFn | undefined

    constructor() {
        makeAutoObservable(this)
    }

    reset() {
        this.status = undefined
        this.correlationId = undefined
        this.lastUpdate = undefined
        this.waiting = false
    }

    async trigger(postcode: string, city: string) {
        this.reset()
        this.correlationId = (Date.now() + Math.random()).toString()
        this.waiting = true
        try {
            await api.v1.triggerRegistrationOfficePortalAvailabilityCheck(postcode, {
                correlationId: this.correlationId,
                city: city
            })
        } catch (e) {
            // we don't want to show any message if this error occurs
            console.log(e)
        }
    }

    notify(dto: RegistrationOfficePortalAvailabilityDto) {
        if (dto.correlationId === this.correlationId) {
            this.waiting = false
            this.status = dto.status
            this.lastUpdate = dto.lastModificationDate
        }

        if (dto.correlationId === this._subscribedId) {
            if (this._subscriberFn) {
                this._subscriberFn(dto)
            }
            this._subscribedId = undefined
            this._subscriberFn = undefined
        }
    }

    subscribeTo(correlationId: string, fn: SubscriberFn) {
        this._subscribedId = correlationId
        this._subscriberFn = fn
    }
}

const registrationOfficePortalAvailabilityStore = new RegistrationOfficePortalAvailabilityStore()

export {
    registrationOfficePortalAvailabilityStore
}
