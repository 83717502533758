import {observer} from "mobx-react";
import {AppHeader} from "../../app";
import {Scroller} from "../../ui/Scroller";
import {useParams} from "react-router-dom";
import {organizationListStore, userStore} from "../../../store";
import {OrganizationList} from "../organization/OrganizationList";
import {UserOrganizationListElement} from "./UserOrganizationListElement";
import {UserDetailTab} from "./UserDetailTab";
import {Tab} from "../../ui";

const UserDetailPage = observer(function UserDetailPage() {
    let {userId, tab} = useParams();

    const user = userStore.user
    const iconClass = user?.active ? "active" : "inactive"

    return <>
        <AppHeader title={`Benutzer: ${user?.username || userId}`}>
            <button className={"button is-primary"} onClick={async () => {
                await userStore.toggleActive(user)
            }}>
                <span className={`material-symbols-outlined ${iconClass}`}
                      style={{cursor: "pointer"}}>{user?.active ? 'toggle_on' : 'toggle_off'}</span>
                <span
                    style={{marginLeft: '0.5em', width: '9.5em', textAlign: 'left'}}>Benutzer {user?.active ? 'deaktivieren' : 'aktivieren'}</span>
            </button>
        </AppHeader>

        <div className="tabs">
            <ul>
                <Tab title={"Übersicht"} name={"detail"} defaultTab={true} path={`/organization/user/${userId}/detail`}/>
                <Tab title={"Standorte"} name={"organization"} path={`/organization/user/${userId}/organization`}/>
            </ul>
        </div>
        <Scroller>
            {(tab === 'detail' || !tab) && user && <UserDetailTab user={user}/>}
            {tab === 'organization' && <OrganizationList
                itemRenderer={(organization) => <UserOrganizationListElement organization={organization}/>}
                elements={organizationListStore.tree}
                expanded={true}/>}
        </Scroller>
    </>
})

export {
    UserDetailPage
}
