import React, {FocusEventHandler, useState} from "react";
import {phoneCountryCodes} from "./FieldPhoneCountryCodes";

const allCodes = Array.from(Object.values(phoneCountryCodes)
    .reduce((buffer: Map<string, boolean>, val: string[]) => {
        val.forEach(s => {
            buffer.set(s, true)
        })
        return buffer
    }, new Map<string, boolean>())
    .keys()).map(v => `+${v}`).sort()

export interface PhoneNumber {
    countryCode: string,
    number: string,
}

interface FieldInputProps {
    required?: boolean
    disabled?: boolean
    label?: string
    value?: PhoneNumber
    invalid?: boolean
    help?: string
    placeholder?: string
    onBlur?: FocusEventHandler<any> | undefined
    onChange?: (value: PhoneNumber | undefined) => void
}

function FieldPhone({
                        required,
                        disabled,
                        label,
                        value,
                        invalid,
                        help,
                        placeholder,
                        onBlur,
                        onChange,
                    }: FieldInputProps) {
    const [country, setCountry] = useState(value?.countryCode || '+49')
    const [phoneNumber, setPhoneNumber] = useState(value?.number)


    return <>
        <div className={`field ${required ? 'required' : ''}`}>
            {label && <label className={`label`}>{label}</label>}
            <div className={'field has-addons'}>
                <div className="control" style={{width: "5.5em"}}>
                    <div className="select">
                        <select onBlur={onBlur} disabled={disabled} value={country} onChange={e => {
                            const val = e.target.value
                            setCountry(val)
                            if (onChange) {
                                if (val && phoneNumber) {
                                    onChange({
                                        countryCode: country,
                                        number: val
                                    })
                                } else {
                                    onChange(undefined)
                                }
                            }
                        }}>
                            {allCodes.map(cc => <option key={cc}>{cc}</option>)}
                        </select>
                    </div>
                </div>
                <div className="control is-expanded">
                    <input
                        disabled={disabled}
                        className={`input ${invalid ? 'is-danger' : ''} ${required ? 'required' : ''}`}
                        type={"text"}
                        value={phoneNumber}
                        placeholder={placeholder}
                        onBlur={onBlur}
                        onChange={e => {
                            const val = e.target.value
                            setPhoneNumber(val)
                            if (onChange) {
                                if (country && val) {
                                    onChange({
                                        countryCode: country,
                                        number: val
                                    })
                                } else {
                                    onChange(undefined)
                                }
                            }
                        }}
                    />
                </div>
            </div>
            <p className={`help ${invalid ? 'is-danger' : ''}`}>{help}</p>
        </div>
    </>
}

export {
    FieldPhone
}
