import {makeAutoObservable} from "mobx";
import {api, catchApi, ErrorDto, RegistrationDto} from "../api";

class RegistrationValidationStore {
    set activated(value: boolean) {
        this._activated = value;
    }
    get activated(): boolean {
        return this._activated;
    }
    get id(): string | undefined {
        return this._id;
    }

    set id(value: string | undefined) {
        this._id = value;
    }
    get validation(): ErrorDto[] {
        return this._validation;
    }

    set validation(value: ErrorDto[]) {
        this._validation = value;
    }

    get valid(): boolean {
        return this.validation?.length === 0
    }

    private abortController: AbortController | undefined;
    private _id: string | undefined
    private _activated: boolean = false
    private _validation: ErrorDto[] = []
    constructor() {
        makeAutoObservable(this)
    }

    async validate(data?: RegistrationDto) {
        if (data) {
            try {
                if (this.abortController) {
                    this.abortController.abort()
                }
                this.abortController = new AbortController()
                const validationResponse = await api.v1.validateCandidate(data, {
                    signal: this.abortController.signal
                })
                this.validation = validationResponse.data || []
            } catch (e) {}
            return
        }
        const id = this.id
        if (!id) {
            return
        }
        await catchApi(async () => {
            const validationResponse = await api.v1.getValidation(id)
            this.validation = validationResponse.data || []
        })
    }

    getFor(key: string): string | undefined {
        const errorDto = this.validation.filter(e => e.fieldName === key)[0]
        return this.activated ? errorDto?.message : undefined
    }

    hasAnyStartingWith(key: string): boolean {
        const errorDto = this.validation.filter(e => e.fieldName.startsWith(key))[0]
        return this.activated ? Boolean(errorDto?.message) : false
    }
}

const registrationValidationStore = new RegistrationValidationStore()

export {
    registrationValidationStore
}
