import {observer} from "mobx-react";
import {organizationUserGroupStore} from "../../../store";
import {ScrollTable} from "../../ui/ScrollTable";
import {useNavigate} from "react-router-dom";

const OrganizationUserGroupList = observer(function OrganizationUserGroupList() {
    const navigate = useNavigate()
    return <div style={{display: "flex", flex: "1", marginTop: "-1.5em"}}>
        <ScrollTable>
            <thead>
            <tr>
                <th>Benutzername</th>
                <th>Vorname</th>
                <th>Nachname</th>
                <th>Rolle</th>
            </tr>
            </thead>
            <tbody className={"user-table"}>
            {organizationUserGroupStore.users.map(user => {
                return <tr>
                    <td><a href={`organization/user/${user.id}`} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate(`/organization/branch/${user.id}`)
                    }}>{user.username}</a></td>

                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{organizationUserGroupStore.userGroup[user.id]}</td>

                </tr>
            })}
            </tbody>
        </ScrollTable>
    </div>
})

export {
    OrganizationUserGroupList
}
