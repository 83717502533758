import {makeAutoObservable} from "mobx";
import {api, catchApi, UserDto} from "../api";

//UserStore
class UserStore {
    get user(): UserDto | undefined {
        return this._user;
    }

    set user(value: UserDto | undefined) {
        this._user = value;
    }

    get userId(): string | undefined {
        return this._userId;
    }

    set userId(value: string | undefined) {
        this._userId = value;
    }

    async setUser(userId: any) {
        if (userId) {
            this.userId = userId as string
            await this.load()
        } else {
            this.userId = undefined
        }
    }


    constructor() {
        makeAutoObservable(this)
    }

    private _user: UserDto | undefined = undefined
    private _userId: string | undefined = undefined

    async load() {
        const {userId} = this
        if (userId) {
            await catchApi(async () => {
                const response = await api.v1.getUser(userId)
                this.user = response.data
            })
        }
    }

    async toggleActive(user: UserDto | undefined) {
        const id = user?.id
        const active = user?.active
        if (id) {
            await catchApi(async () => {
                await api.v1.updateActive(id, !active)
            })
        }
    }
}

const userStore = new UserStore()

export {
    userStore
}
