import {observer} from "mobx-react";
import {useFormik} from "formik";
import * as yup from "yup";
import {UserCreateStore} from "../../../store";

interface UserCreateFormProps {
    store: UserCreateStore
}

const UserCreateForm = observer(function UserCreateForm({store}: UserCreateFormProps) {
    const validationSchema = yup.object({
        email: yup
            .string()
            .email('Tragen Sie hier die E-Mailadresse des Benutzers ein')
            .required('Tragen Sie hier die E-Mailadresse des Benutzers ein'),
        username: yup
            .string()
            .min(4, 'Mindestens 4 Zeichen')
            .max(32, 'Maximal 32 Zeichen')
            .required('Tragen Sie hier den eindeutigen Benutzernamen ein'),
        firstname: yup
            .string()
            .min(2, 'Mindestens 2 Zeichen')
            .max(32, 'Maximal 64 Zeichen')
            .required('Tragen Sie hier den Vornamen des Benutzers ein'),
        lastname: yup
            .string()
            .min(2, 'Mindestens 2 Zeichen')
            .max(32, 'Maximal 64 Zeichen')
            .required('Tragen Sie hier den Nachnamen des Benutzers ein'),
    });
    const formik = useFormik({
        initialValues: {
            email: '',
            username: '',
            firstname: '',
            lastname: '',
        },
        validationSchema: validationSchema,
        onSubmit: () => {},
    });

    store.formik = formik

    const usernameError = formik.touched.username && Boolean(formik.errors.username)
    const usernameErrorText = (formik.touched.username && formik.errors.username)

    const emailError = formik.touched.email && Boolean(formik.errors.email)
    const emailErrorText = (formik.touched.email && formik.errors.email)
    const firstnameError = formik.touched.firstname && Boolean(formik.errors.firstname)
    const firstnameErrorText = (formik.touched.firstname && formik.errors.firstname)
    const lastnameError = formik.touched.lastname && Boolean(formik.errors.lastname)
    const lastnameErrorText = (formik.touched.lastname && formik.errors.lastname)
    return <>
        <div className="columns">
            <div className="column is-half">
                <div className="field">
                    <label className="label">Benutzername</label>
                    <div className="control">
                        <input
                            type="text"
                            className={`input ${usernameError ? "is-danger" : ""}`}
                            name={"username"}
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Benutzername"
                        />
                    </div>
                    <p className={`help ${usernameError ? "is-danger" : ""}`}>{usernameErrorText || 'Tragen Sie hier den eindeutigen Benutzernamen ein'}</p>
                </div>
            </div>
            <div className="column is-half">
                <div className="field">
                    <label className="label">E-Mail</label>
                    <div className="control">
                        <input
                            type="email"
                            name={"email"}
                            placeholder="E-Mail"
                            className={`input ${emailError ? "is-danger" : ""}`}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <p className={`help ${emailError ? "is-danger" : ""}`}>{emailErrorText || 'Tragen Sie hier die E-Mailadresse des Benutzers ein'}</p>
                </div>
            </div>
        </div>
        <div className="columns">
            <div className="column is-half">
                <div className="field">
                    <label className="label">Vorname</label>
                    <div className="control">
                        <input
                            type="text"
                            placeholder="Vorname"
                            name={"firstname"}
                            className={`input ${usernameError ? "is-danger" : ""}`}
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <p className={`help ${firstnameError ? "is-danger" : ""}`}>{firstnameErrorText || 'Tragen Sie hier den Vornamen des Benutzers ein'}</p>
                    <p className="help"></p>
                </div>
            </div>
            <div className="column is-half">
                <div className="field">
                    <label className="label">Nachname</label>
                    <div className="control">
                        <input
                            type="text"
                            placeholder="Nachname"
                            name={"lastname"}
                            className={`input ${lastnameError ? "is-danger" : ""}`}
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <p className={`help ${lastnameError ? "is-danger" : ""}`}>{lastnameErrorText || 'Tragen Sie hier den Nachnamen des Benutzers ein'}</p>
                </div>
            </div>
        </div>
    </>
})

export {
    UserCreateForm
}
