import {ConfirmationProps} from "./confirmation/ConfirmationFormCommons";
import {JSX} from "react";
import {registrationStore} from "../../../../store";
import {
    orderType,
    RegistrationDtoOrderStatusEnum,
    RegistrationDtoServiceTypeEnum,
    RegistrationListItemDtoOrderStatusEnum
} from "../../../../api";
import {ConfirmationFormNz} from "./servicetype/NZ/ConfirmationFormNz";
import {
    ConfirmationFormAb,
    ConfirmationFormTz,
    ConfirmationFormUi,
    ConfirmationFormUm,
    ConfirmationFormUo,
    ConfirmationFormWz
} from "./confirmation/ConfirmationForm";
import {DetailNz} from "./servicetype/NZ/DetailNz";
import {DetailWz} from "./servicetype/WZ/DetailWz";
import {DetailUm} from "./servicetype/UM/DetailUm";
import {DetailUo} from "./servicetype/UO/DetailUo";
import {DetailUi} from "./servicetype/UI/DetailUi";
import {DetailAb} from "./servicetype/AB/DetailAb";
import {DetailTz} from "./servicetype/TZ/DetailTz";
import {DetailHa} from "./servicetype/HA/DetailHa";
import {ConfirmationFormHa} from "./servicetype/HA/ConfirmationFormHa";

export function getConfirmationElement(): ({dialogStore}: ConfirmationProps) => JSX.Element {
    const serviceType = registrationStore.serviceType
    if (!serviceType) {
        return () => <></>
    }
    switch (serviceType) {
        case RegistrationDtoServiceTypeEnum.NZ:
            return ConfirmationFormNz
        case RegistrationDtoServiceTypeEnum.TZ:
            return ConfirmationFormTz
        case RegistrationDtoServiceTypeEnum.AB:
            return ConfirmationFormAb
        case RegistrationDtoServiceTypeEnum.UI:
            return ConfirmationFormUi
        case RegistrationDtoServiceTypeEnum.UO:
            return ConfirmationFormUo
        case RegistrationDtoServiceTypeEnum.UM:
            return ConfirmationFormUm
        case RegistrationDtoServiceTypeEnum.WZ:
            return ConfirmationFormWz
        case RegistrationDtoServiceTypeEnum.HA:
            return ConfirmationFormHa
    }
}

export function getDetailComponent() {
    const serviceType = registrationStore.serviceType
    if (!serviceType) {
        return () => <></>
    }
    switch (serviceType) {
        case RegistrationDtoServiceTypeEnum.NZ:
            return DetailNz
        case RegistrationDtoServiceTypeEnum.TZ:
            return DetailTz
        case RegistrationDtoServiceTypeEnum.AB:
            return DetailAb
        case RegistrationDtoServiceTypeEnum.UI:
            return DetailUi
        case RegistrationDtoServiceTypeEnum.UO:
            return DetailUo
        case RegistrationDtoServiceTypeEnum.UM:
            return DetailUm
        case RegistrationDtoServiceTypeEnum.WZ:
            return DetailWz
        case RegistrationDtoServiceTypeEnum.HA:
            return DetailHa
    }
}


export function getStatusText(): string | undefined {
    switch (registrationStore.status) {
        case RegistrationDtoOrderStatusEnum.SUBMITTED:
            return 'beantragt'
        case RegistrationDtoOrderStatusEnum.SUCCESS:
            return 'erfolgreich'
        case RegistrationDtoOrderStatusEnum.FINISHED:
            return 'abgeschlossen'
        case RegistrationDtoOrderStatusEnum.SUBMISSION_FAILED:
            return 'konnte nicht beantragt werden'
        case RegistrationDtoOrderStatusEnum.FAILED:
            return 'fehlgeschlagen'
    }
    if (registrationStore.signatureProcessStarted) {
        return 'Die Dokumentsignierung wurde beantragt'
    }
    if (registrationStore.signatureProcessFinished) {
        return 'Die Dokumente wurden signiert.'
    }
    return
}

export function getStatusIconFor(
    status: RegistrationDtoOrderStatusEnum | RegistrationListItemDtoOrderStatusEnum,
    signatureProcessStartet: boolean | undefined,
    signatureProcessFinished: boolean | undefined,
) {
    switch (status) {
        case RegistrationDtoOrderStatusEnum.SUBMITTED:
            return 'arrow_circle_right'
        case RegistrationDtoOrderStatusEnum.SUCCESS:
            return 'check_circle'
        case RegistrationDtoOrderStatusEnum.FINISHED:
            return 'check'
        case RegistrationDtoOrderStatusEnum.SUBMISSION_FAILED:
            return 'warning'
        case RegistrationDtoOrderStatusEnum.FAILED:
            return 'cancel'
    }
    if (signatureProcessStartet || signatureProcessFinished) {
        return 'signature'
    }
    if (status === RegistrationDtoOrderStatusEnum.CREATED) {
        return 'edit'
    }
    return
}

export function getStatusIcon(): string | undefined {
    return getStatusIconFor(registrationStore.status, registrationStore.signatureProcessStarted, registrationStore.signatureProcessFinished)
}

export function getStatusColor(): string | undefined {
    switch (registrationStore.status) {
        case RegistrationDtoOrderStatusEnum.SUBMITTED:
            return '--ct-primary-blue-color'
        case RegistrationDtoOrderStatusEnum.SUCCESS:
            return '--ct-success-color-bright'
        case RegistrationDtoOrderStatusEnum.FINISHED:
            return '--ct-primary-blue-color'
        case RegistrationDtoOrderStatusEnum.SUBMISSION_FAILED:
            return '--ct-warning-color'
        case RegistrationDtoOrderStatusEnum.FAILED:
            return '--ct-danger-color'
    }
    if (registrationStore.signatureProcessStarted) {
        return '--ct-primary-highlight-color'
    }
    if (registrationStore.signatureProcessFinished) {
        return '--ct-success-color-bright'
    }
    return
}

export function getPageTitle(type: RegistrationDtoServiceTypeEnum | undefined, operation: string) {
    const titleString = !type ? `Registrierung ${operation}` : `${orderType(type)} ${operation}`

    const statusIcon = getStatusIcon()
    if (statusIcon) {
        const statusColor = getStatusColor()!!
        const statusText = getStatusText()!!
        return <>
                {titleString}
            <span style={{paddingLeft: '0.1em', display: 'flex'}}>
        <span className="material-symbols-outlined" style={{
            color: `var(${statusColor})`,
                padding: '0.2em',
                cursor: 'default'
        }} title={statusText}>{statusIcon}</span>
            </span>
            <span style={{
            fontSize: '0.9em',
                fontWeight: '400',
                color: 'var(--ct-font-color-variant-or-disabled-buttons)'
        }}>{statusText}</span>
        </>
    }

    if (registrationStore.signatureProcessStarted) {
        return <>
                {titleString}
            <span style={{paddingLeft: '1em', display: 'flex'}}>
        <span className="material-symbols-outlined" style={{
            backgroundColor: 'var(--ct-primary-highlight-color)',
                borderRadius: '50%',
                padding: '0.2em',
                cursor: 'default',
                color: 'white'
        }} title={"Die Dokumentsignierung wurde beantragt."}>signature</span>
            </span>
            </>
    }
    return titleString
}
