import {observer} from "mobx-react";
import {Navbar} from "../ui";
import {user} from "../page";

const UserDetailApp = observer(function UserDetailApp() {
    return <div className={"app-wrapper"}>
        <Navbar />
        <user.UserDetailPage />
    </div>
})

export {
    UserDetailApp
}
