import {observer} from "mobx-react";
import {organizationStore} from "../../../store";
import {Scroller} from "../../ui/Scroller";

const OrganizationForm = observer(function OrganizationForm() {
    return <>
        <Scroller>
        <div className="field">
            <label className="label">Standortname</label>
            <div className="control">
                <input
                    className="input"
                    type="text"
                    placeholder="Name des Standorts"
                    value={organizationStore.name}
                    onChange={e => {
                        organizationStore.name = e.currentTarget.value
                    }}
                />
            </div>
            <p className="help">Tragen Sie hier den eindeutigen Namen des Standortes ein.</p>
        </div>
        <div className="field">
            <label className="label">Standortzuordnung</label>
            <div className="control">
                <select
                    className="select" defaultValue={organizationStore.parent} onChange={e => {
                    organizationStore.parent = e.currentTarget.value
                }}>
                    <option
                        value={""}>
                        kein übergeordneter Standort
                    </option>
                    {organizationStore.tenantOrganizationParentOptions.map((option) => {
                        return (
                            <option
                                key={option.id}
                                value={option.id}>
                                {option.name}
                            </option>
                        );
                    })}
                </select>
            </div>
            <p className="help">Tragen Sie hier den eindeutigen Namen des Standortes ein der als übergeordneter Standort
                dienen soll.</p>
        </div>
        <div className="field">
            <label className="label">Kostenstelle</label>
            <div className="control">
                <input
                    className="input"
                    type="text"
                    placeholder="Kostenstelle"
                    value={organizationStore.costCenter}
                    onChange={e => {
                        organizationStore.costCenter = e.currentTarget.value
                    }}
                />
            </div>
            <p className="help">Tragen Sie hier die Kostenstelle des Standortes ein.</p>
        </div>

        </Scroller>
    </>
})

export {
    OrganizationForm
}
