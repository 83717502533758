import {observer} from "mobx-react";
import {registrationStore} from "../../../../../../store";
import {FieldInput} from "../../../../../ui";
import {FieldDate} from "../../../../../ui/FieldDate";
import {registrationValidationStore} from "../../../../../../store/RegistrationValidationStore";
import {RegistrationLicensePlateField} from "../../../fields/RegistrationLicensePlateField";
import {Checkbox} from "../../../../../ui/Checkbox";

const PreviousRegistrationPanelAb = observer(function PreviousRegistrationPanel() {
    const certificateOfDestructionDateHelp = registrationValidationStore.getFor('certificateOfDestruction.date')
    const certificateOfDestructionCompanyNumberHelp = registrationValidationStore.getFor('certificateOfDestruction.companyNumber')

    return <>
        <nav className="panel">
            <p className="panel-heading">Informationen vorheriger Zulassung</p>
            <div className="panel-block">
                <div>
                    <div className={"columns"}>
                        <div className={"column is-half"} style={{display: "flex"}}>
                            <RegistrationLicensePlateField licensePlateField={'previousLicensePlate'}/>
                        </div>
                        <div className={"column is-half"}>
                            <div>
                                <Checkbox
                                    label={'Fahrzeugbezogen reservieren'}
                                    disabled={registrationStore.isReadonly}
                                    value={registrationStore.previousLicensePlateReservation}
                                    onChange={checked => {
                                        registrationStore.previousLicensePlateReservation = checked
                                    }}
                                    onBlur={() => registrationStore.validate()}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    label={'Fahrzeug wird verschrottet'}
                                    disabled={registrationStore.isReadonly}
                                    value={registrationStore.certificateOfDestruction !== undefined}
                                    onChange={checked => {
                                        registrationStore.certificateOfDestruction = checked ? {
                                            date: '',
                                            companyNumber: ''
                                        } : undefined
                                    }}
                                    onBlur={() => registrationStore.validate()}
                                />
                            </div>
                            {registrationStore.certificateOfDestruction ?<div style={{paddingTop: '1em'}}>
                                <FieldInput
                                    disabled={registrationStore.isReadonly}
                                    invalid={Boolean(certificateOfDestructionCompanyNumberHelp)}
                                    help={certificateOfDestructionCompanyNumberHelp}
                                    label={"Nummer des Demontagebetriebs"}
                                    value={registrationStore.certificateOfDestruction.companyNumber}
                                    onBlur={() => registrationStore.validate()}
                                    onChange={v => {
                                        registrationStore.certificateOfDestruction = {
                                            date: registrationStore.certificateOfDestruction?.date!!,
                                            companyNumber: v
                                        }
                                    }}
                                />
                                <FieldDate
                                    label={"Datum des Verwertungsnachweises"}
                                    invalid={Boolean(certificateOfDestructionDateHelp)}
                                    help={certificateOfDestructionDateHelp}
                                    value={registrationStore.certificateOfDestruction.date}
                                    onBlur={() => registrationStore.validate()}
                                    onChange={v => {
                                        registrationStore.certificateOfDestruction = {
                                            date: v,
                                            companyNumber: registrationStore.certificateOfDestruction?.companyNumber!!
                                        }
                                    }}
                                />
                            </div>:<></>}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </>
})

export {
    PreviousRegistrationPanelAb
}
