import {observer} from "mobx-react";
import {organizationUserGroupStore, UserOption} from "../../../store";
import {AutoComplete} from "../../ui/AutoComplete";

const OrganizationUserGroupCreateForm = observer(function OrganizationUserGroupCreateForm() {
    return <div style={{display: "flex", flex: "1", marginTop: "-1.5em", height: "10em"}}>
        <div className="field">
            <label className="label">Benutzer zuordnen</label>
            <div className={"columns"}>
                <div className={"column"}>

                    <AutoComplete
                        value={organizationUserGroupStore.userMapping}
                        optionsHandler={async (value) => {
                            return await organizationUserGroupStore.searchUserOptions(value)
                        }} onSelect={(option) => {
                        organizationUserGroupStore.userMapping = option as UserOption
                    }}/>
                    <p className="help">Benutzerauswahl</p>
                </div>
                <div className={"column"}>
                    <div className={"select"}>
                        <select
                            defaultValue={organizationUserGroupStore.userRole} onChange={e => {
                            organizationUserGroupStore.userRole = e.currentTarget.value as ("ADMIN" | "USER")
                        }}>
                            <option
                                value={"USER"}>
                                Benutzer
                            </option>
                            <option
                                value={"ADMIN"}>
                                Leiter
                            </option>
                        </select>
                    </div>
                    <p className="help">Rollenauswahl</p>
                </div>
            </div>
        </div>
    </div>
})

export {
    OrganizationUserGroupCreateForm
}
