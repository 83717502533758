import {observer} from "mobx-react";
import {ModalStore, modalStore} from "../../store";
import {ReactNode} from "react";

interface ModalProps {
    store?: ModalStore,
    closeOnBackgroundClick?: boolean,
    children?: ReactNode,
    showModalCloseButton?: boolean
}

function backgroundClick(store: ModalStore, closeOnBackgroundClick?: boolean) {
    if (closeOnBackgroundClick) {
        (store).close()
    }
}

function modalCloseButtonClick(store: ModalStore) {
    store.close()
}

const Modal = observer(function Modal({
                                     store,
                                     closeOnBackgroundClick,
                                     children,
                                     showModalCloseButton
                                 }: ModalProps) {
    const storeToUse = (store || modalStore)
    return storeToUse.isOpen ? <div className={`modal is-active`}>
        <div className="modal-background" onClick={() => {
            backgroundClick(storeToUse, closeOnBackgroundClick)
        }}></div>
        <div className="modal-content">
            {children}
        </div>
        {showModalCloseButton && <button className="modal-close is-large" aria-label="close" onClick={() => {
            modalCloseButtonClick(storeToUse)
        }}></button>}
    </div> : <></>
})

export {
    Modal
}
