import {observer} from "mobx-react";
import {Navbar} from "../ui";
import {user} from "../page";

const UserOverviewApp = observer(function UserOverviewApp() {
    return <div className={"app-wrapper"}>
        <Navbar />
        <user.UserOverviewPage />
    </div>
})

export {
    UserOverviewApp
}
