import {observer} from "mobx-react";
import {Scroller} from "../../../ui/Scroller";
import {AppHeader} from "../../../app";
import {useEffect, useState} from "react";
import {authStore, DialogStore, featureStore, registrationStore} from "../../../../store";
import {registrationValidationStore} from "../../../../store/RegistrationValidationStore";
import {Dialog, Growl, Tab} from "../../../ui";

import "./RegistratonDetailPage.scss"
import {getDetailComponent, getPageTitle} from "./RegistrationDetailHelper";
import {RegistrationDetailConfirmDialog} from "./RegistrationDetailConfirmDialog";
import {RegistrationNotificationBar} from "./notificattion/RegistrationNotificationBar";
import {useParams} from "react-router-dom";
import {RequestLog} from "./RequestLog";

const SignatureButton = observer(function SignatureButton() {
    const [signatureStarting, setSignatureStarting] = useState(false)

    async function startSignature() {
        try {
            setSignatureStarting(true)
            await registrationStore.startSignature()
        } finally {
            setSignatureStarting(false)
        }
    }

    return <>
        {registrationStore.documentsReadyForSignature && <button
            onClick={() => startSignature()}
            disabled={registrationStore.dirty}
            className={`button is-primary ${signatureStarting ? 'is-loading' : ''}`}>Fernsignatur starten</button>}
    </>
})

const SaveButton = observer(function SaveButton() {
    return <>
        <button
            className={`button is-primary ${registrationStore.saving ? 'is-loading' : ''}`}
            disabled={!registrationStore.dirty}
            onClick={async () => {
                await registrationStore.save()
            }}
        >Auftrag speichern
        </button>
    </>
})

const SubmitButton = observer(function SubmitButton({store}: {
    store: DialogStore
}) {
    return <>
        {registrationValidationStore.valid && !registrationStore.isReadonly
            && <button
                onClick={() => {
                    store.primaryReady = false
                    store.open()
                }}
                disabled={registrationStore.dirty}
                className={`button is-primary`}>Beantragen</button>}
    </>
})

const ArchiveButton = observer(function ArchiveButton({store}: {
    store: DialogStore
}) {
    return <>
        {authStore.isAdmin &&
            (registrationStore.archived ? <>
                <button onClick={() => registrationStore.unarchive()}
                        className={`button is-info ${registrationStore.archiving ? 'is-loading' : ''}`}
                        title={`Vorgang wiederherstellen`}>
                        <span
                            className="material-symbols-outlined">unarchive</span>
                </button>
            </> : <>
                <button onClick={() => store.open()}
                        className={`button is-warning ${registrationStore.archiving ? 'is-loading' : ''}`}
                        title={`Vorgang archivieren`}>
                        <span
                            className="material-symbols-outlined">archive</span>
                </button>
            </>)}
    </>
})

const ArchiveDialog = observer(function ArchiveDialog({store}: {
    store: DialogStore
}) {
    return <>
        <Dialog store={store} title={"Bitte bestätigen Sie den Archivierungsvorgang!"}
                primaryAction={async () => {
                    store.close()
                    await registrationStore.archive()
                    return null
                }} primaryActionTitle={"Archivieren"}>

        </Dialog>
    </>
})

const RegistrationDetailPage = observer(function RegistrationDetailPage() {
    const [archiveStore] = useState(new DialogStore())
    const [confirmStore] = useState(new DialogStore())
    useEffect(() => {
        confirmStore.data = registrationStore.submitDto
    }, [confirmStore]);

    const params = useParams()
    const requestLogActive = params.tab === 'requestlog'
    const id = params.id!!

    const showRequestLog = authStore.isAdmin && featureStore.isActive("REGISTRATION_REQUEST_LOG")

    const DetailElement = getDetailComponent()

    const operation = registrationStore.original ? (registrationStore.isReadonly ? '' : "bearbeiten") : "anlegen"

    return <>
        <AppHeader title={getPageTitle(registrationStore.serviceType, operation)}>
            {!requestLogActive && <SignatureButton/>}
            {!requestLogActive && <SaveButton/>}
            {!requestLogActive && <SubmitButton store={confirmStore}/>}
            <ArchiveButton store={archiveStore}/>
        </AppHeader>
        {showRequestLog && <div className="tabs">
            <ul>
                <Tab title={"Antrag"} name={"antrag"} defaultTab={true}
                     path={`/registration/${id}`}/>
                <Tab title={"Anfrageprotokoll"} name={"requestlog"}
                     path={`/registration/${id}/requestlog`}/>
            </ul>
        </div>}
        {requestLogActive && showRequestLog
            ? <RequestLog/>
            : <>
                <RegistrationNotificationBar/>
                <Scroller>
                    <div className={`registration-detail-page ${registrationStore.archived ? 'archived' : ''}`}>
                        {DetailElement ? <DetailElement></DetailElement> : <></>}
                    </div>
                </Scroller>
            </>}

        <ArchiveDialog store={archiveStore}/>
        <RegistrationDetailConfirmDialog store={confirmStore}/>
        <Growl/>
        {(registrationStore.signatureProcessStarted && registrationStore.data?.signingProcessId) ? <>
            <input id={'signing-process-id'} style={{display: 'none'}} defaultValue={registrationStore.data.signingProcessId}/>
        </>:<></>}
    </>
})

export {
    RegistrationDetailPage
}
