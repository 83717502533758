import {observer} from "mobx-react";
import React from "react";
import {DeliveryAddressDtoGenderEnum, deliveryAddressGender} from "../../../../../api";
import {CitySelect, FieldInput, FieldSelect} from "../../../../ui";
import {DeliveryFormData, registrationStore} from "../../../../../store";

interface DeliveryPickupInfoFormProps {
    disabled: boolean,
    value: DeliveryFormData,
    onChange: (e: DeliveryFormData) => void
}

const DeliveryPickupInfoForm = observer(function DeliveryPickupInfoForm({
                                                                            disabled,
                                                                            value,
                                                                            onChange
                                                                        }: DeliveryPickupInfoFormProps) {

    const titleOptions = Object.entries(DeliveryAddressDtoGenderEnum).map((o) => {
        return {
            value: o[1],
            label: deliveryAddressGender(o[1])
        }
    })

    return <>
        <label className={"checkbox"} style={{paddingBottom: '0.5em'}}>
            <input disabled={disabled} type={"checkbox"} checked={value.corporate} onChange={e => {
                onChange({...value, corporate: e.target.checked})
            }}/>
            Unternehmen
        </label>

        {!value.corporate && <div className={"columns"}>
            <div className={"column is-one-fifth"}>
                <FieldSelect disabled={disabled} label={"Anrede"} options={titleOptions} value={value.title}
                             onChange={e => {
                                 onChange({...value, title: e})
                             }}
                             onBlur={() => registrationStore.validate()}/>
            </div>
            <div className={"column is-two-fifth"}>
                <FieldInput disabled={disabled} required={true} label={"Vorname"} value={value.firstName}
                            onChange={e => {
                                onChange({...value, firstName: e})
                            }}
                            onBlur={() => registrationStore.validate()}/>
            </div>
            <div className={"column is-two-fifth"}>
                <FieldInput disabled={disabled} required={true} label={"Nachname"} value={value.name}
                            onChange={e => {
                                onChange({...value, name: e})
                            }}
                            onBlur={() => registrationStore.validate()}/>
            </div>
        </div>}
        {value.corporate && <div className={"columns"}>
            <div className={"column is-full"}>
                <FieldInput
                    required={true}
                    disabled={disabled}
                    label={"Name des Unternehmens"}
                    value={value.name}
                    onChange={e => {
                        onChange({...value, name: e})
                    }}
                    onBlur={() => registrationStore.validate()}
                />
            </div>
        </div>}

        <div className={"columns"}>
            <div className={"column is-half"}>
                <FieldInput disabled={disabled} required={true} label={"Straße"} value={value.street}
                            onChange={e => {
                                onChange({...value, street: e})
                            }}
                            onBlur={() => registrationStore.validate()}/>
            </div>
            <div className={"column"}>
                <FieldInput disabled={disabled} required={true} label={"Hausnummer"} value={value.houseNumber}
                            onChange={e => {
                                onChange({...value, houseNumber: e})
                            }}
                            onBlur={() => registrationStore.validate()}/>
            </div>
            <div className={"column"}>
                <FieldInput disabled={disabled} label={"Hausnummer Zusatz"} value={value.addition}
                            onChange={e => {
                                onChange({...value, addition: e})
                            }}
                            onBlur={() => registrationStore.validate()}/>
            </div>
        </div>

        <CitySelect disabled={disabled} city={value.city} zip={value.zip} required={true}
                    onChange={e => {
                        onChange({...value, city: e?.city || '', zip: e?.zip || ''})
                    }}
                    onBlur={() => registrationStore.validate()}></CitySelect>
    </>
})

export {
    DeliveryPickupInfoForm
}
