import {makeAutoObservable} from "mobx";

class DropdownStore {
    get openId(): string | undefined {
        return this._openId;
    }

    set openId(value: string | undefined) {
        this._openId = value;
    }

    constructor() {
        makeAutoObservable(this)
    }

    private _openId: string | undefined

    toggle(openId: string | undefined) {
        if (this.openId === openId) {
            this.openId = undefined
        } else {
            this.openId = openId
        }
    }
}

const dropdownStore = new DropdownStore()

window.document.addEventListener('click', function() {
    dropdownStore.openId = undefined
})

export {
    dropdownStore
}
