export enum CourtEnum {
    R3101 = 'R3101',
    Y1201 = 'Y1201',
    D3101 = 'D3101',
    D3201 = 'D3201',
    Y1101 = 'Y1101',
    R1901 = 'R1901',
    Y1102 = 'Y1102',
    Y1303 = 'Y1303',
    D4102 = 'D4102',
    D2102 = 'D2102',
    P3101 = 'P3101',
    M1305 = 'M1305',
    M1202 = 'M1202',
    T2101 = 'T2101',
    R2108 = 'R2108',
    Y1301 = 'Y1301',
    D4201 = 'D4201',
    D4301 = 'D4301',
    R2101 = 'R2101',
    R2201 = 'R2201',
    R3201 = 'R3201',
    P1103 = 'P1103',
    H1101 = 'H1101',
    F1103 = 'F1103',
    U1206 = 'U1206',
    D4401 = 'D4401',
    R2707 = 'R2707',
    G1103 = 'G1103',
    M1103 = 'M1103',
    D2201 = 'D2201',
    R2402 = 'R2402',
    U1104 = 'U1104',
    R1202 = 'R1202',
    R3103 = 'R3103',
    R1101 = 'R1101',
    Y1105 = 'Y1105',
    Y1106 = 'Y1106',
    M1602 = 'M1602',
    R2503 = 'R2503',
    X1112 = 'X1112',
    G1207 = 'G1207',
    M1201 = 'M1201',
    B1204 = 'B1204',
    B8536 = 'B8536',
    M1405 = 'M1405',
    M1603 = 'M1603',
    M1301 = 'M1301',
    D3304 = 'D3304',
    R2507 = 'R2507',
    Y1203 = 'Y1203',
    M1406 = 'M1406',
    Y1108 = 'Y1108',
    P2204 = 'P2204',
    Y1205 = 'Y1205',
    R2103 = 'R2103',
    R2602 = 'R2602',
    K1101 = 'K1101',
    R2404 = 'R2404',
    M1502 = 'M1502',
    P2305 = 'P2305',
    Y1109 = 'Y1109',
    Y1302 = 'Y1302',
    P2408 = 'P2408',
    D4501 = 'D4501',
    V1122 = 'V1122',
    D5701 = 'D5701',
    R2604 = 'R2604',
    Y1206 = 'Y1206',
    T3201 = 'T3201',
    M1607 = 'M1607',
    D2304 = 'D2304',
    X1517 = 'X1517',
    R1304 = 'R1304',
    T2210 = 'T2210',
    R3306 = 'R3306',
    M1203 = 'M1203',
    M1608 = 'M1608',
    R1402 = 'R1402',
    T3304 = 'T3304',
    D2404 = 'D2404',
    V1125 = 'V1125',
    U1308 = 'U1308',
    R2307 = 'R2307',
    M1706 = 'M1706',
    X1721 = 'X1721',
    T3104 = 'T3104',
    P2507 = 'P2507',
    T2304 = 'T2304',
    B1601 = 'B1601',
    B8535 = 'B8535',
    M1809 = 'M1809',
    Y1304 = 'Y1304',
    D2505 = 'D2505',
    V1127 = 'V1127',
    R1504 = 'R1504',
    T2214 = 'T2214',
    Y1110 = 'Y1110',
    D2601 = 'D2601',
    R2713 = 'R2713',
    N1105 = 'N1105',
    V1120 = 'V1120',
    G1309 = 'G1309',
    R1102 = 'R1102',
    Y1111 = 'Y1111',
    D3310 = 'D3310',
    M1114 = 'M1114',
    P3210 = 'P3210',
    P3313 = 'P3313',
    V1124 = 'V1124',
    R2809 = 'R2809',
    D2803 = 'D2803',
    X1321 = 'X1321',
    Y1209 = 'Y1209',
    Y1208 = 'Y1208',
    G1312 = 'G1312',
    R2204 = 'R2204',
    D3410 = 'D3410',
    N1206 = 'N1206',
    Y1210 = 'Y1210',
    V1109 = 'V1109',
    V1121 = 'V1121',
    D4608 = 'D4608',
    N1308 = 'N1308',
    R3208 = 'R3208',
    R2909 = 'R2909',
    Y1112 = 'Y1112',
    Y1113 = 'Y1113',
    Y1307 = 'Y1307',
    V1123 = 'V1123',
    V1126 = 'V1126',
    P2106 = 'P2106',
    Y1214 = 'Y1214',
    R2706 = 'R2706',
    W1215 = 'W1215',
    N1209 = 'N1209',
    D3413 = 'D3413',
    B2609 = 'B2609',
    B8534 = 'B8534',
    Y1308 = 'Y1308',
    P2613 = 'P2613',
    D2910 = 'D2910',
    B2805 = 'B2805',
    B8537 = 'B8537',
    V1128 = 'V1128',
    P2716 = 'P2716',
    D3508 = 'D3508',
    Y1114 = 'Y1114',
    M1710 = 'M1710',
    M1906 = 'M1906',
    T2408 = 'T2408',
    R1608 = 'R1608',
    D4708 = 'D4708',
    T3403 = 'T3403',
}


export function courtName(courtId: CourtEnum): string {
    switch (courtId) {
        case CourtEnum.R3101:
            return 'Amtsgericht Aachen'
        case CourtEnum.Y1201:
            return 'Amtsgericht Altenburg'
        case CourtEnum.D3101:
            return 'Amtsgericht Amberg'
        case CourtEnum.D3201:
            return 'Amtsgericht Ansbach'
        case CourtEnum.Y1101:
            return 'Amtsgericht Apolda'
        case CourtEnum.R1901:
            return 'Amtsgericht Arnsberg'
        case CourtEnum.Y1102:
            return 'Amtsgericht Arnstadt'
        case CourtEnum.Y1303:
            return 'Amtsgericht Arnstadt Zweigstelle Ilmenau'
        case CourtEnum.D4102:
            return 'Amtsgericht Aschaffenburg'
        case CourtEnum.D2102:
            return 'Amtsgericht Augsburg'
        case CourtEnum.P3101:
            return 'Amtsgericht Aurich'
        case CourtEnum.M1305:
            return 'Amtsgericht Bad Hersfeld'
        case CourtEnum.M1202:
            return 'Amtsgericht Bad Homburg v. d. Höhe'
        case CourtEnum.T2101:
            return 'Amtsgericht Bad Kreuznach'
        case CourtEnum.R2108:
            return 'Amtsgericht Bad Oeynhausen'
        case CourtEnum.Y1301:
            return 'Amtsgericht Bad Salzungen'
        case CourtEnum.D4201:
            return 'Amtsgericht Bamberg'
        case CourtEnum.D4301:
            return 'Amtsgericht Bayreuth'
        case CourtEnum.R2101:
            return 'Amtsgericht Bielefeld'
        case CourtEnum.R2201:
            return 'Amtsgericht Bochum'
        case CourtEnum.R3201:
            return 'Amtsgericht Bonn'
        case CourtEnum.P1103:
            return 'Amtsgericht Braunschweig'
        case CourtEnum.H1101:
            return 'Amtsgericht Bremen'
        case CourtEnum.F1103:
            return 'Amtsgericht Charlottenburg'
        case CourtEnum.U1206:
            return 'Amtsgericht Chemnitz'
        case CourtEnum.D4401:
            return 'Amtsgericht Coburg'
        case CourtEnum.R2707:
            return 'Amtsgericht Coesfeld'
        case CourtEnum.G1103:
            return 'Amtsgericht Cottbus'
        case CourtEnum.M1103:
            return 'Amtsgericht Darmstadt'
        case CourtEnum.D2201:
            return 'Amtsgericht Deggendorf'
        case CourtEnum.R2402:
            return 'Amtsgericht Dortmund'
        case CourtEnum.U1104:
            return 'Amtsgericht Dresden'
        case CourtEnum.R1202:
            return 'Amtsgericht Duisburg'
        case CourtEnum.R3103:
            return 'Amtsgericht Düren'
        case CourtEnum.R1101:
            return 'Amtsgericht Düsseldorf'
        case CourtEnum.Y1105:
            return 'Amtsgericht Eisenach'
        case CourtEnum.Y1106:
            return 'Amtsgericht Erfurt'
        case CourtEnum.M1602:
            return 'Amtsgericht Eschwege'
        case CourtEnum.R2503:
            return 'Amtsgericht Essen'
        case CourtEnum.X1112:
            return 'Amtsgericht Flensburg'
        case CourtEnum.G1207:
            return 'Amtsgericht Frankfurt (Oder)'
        case CourtEnum.M1201:
            return 'Amtsgericht Frankfurt am Main'
        case CourtEnum.B1204:
            return 'Amtsgericht Freiburg im Breisgau'
        case CourtEnum.B8536:
            return 'Amtsgericht Freiburg im Breisgau'
        case CourtEnum.M1405:
            return 'Amtsgericht Friedberg (Hessen)'
        case CourtEnum.M1603:
            return 'Amtsgericht Fritzlar'
        case CourtEnum.M1301:
            return 'Amtsgericht Fulda'
        case CourtEnum.D3304:
            return 'Amtsgericht Fürth'
        case CourtEnum.R2507:
            return 'Amtsgericht Gelsenkirchen'
        case CourtEnum.Y1203:
            return 'Amtsgericht Gera'
        case CourtEnum.M1406:
            return 'Amtsgericht Gießen'
        case CourtEnum.Y1108:
            return 'Amtsgericht Gotha'
        case CourtEnum.P2204:
            return 'Amtsgericht Göttingen'
        case CourtEnum.Y1205:
            return 'Amtsgericht Greiz'
        case CourtEnum.R2103:
            return 'Amtsgericht Gütersloh'
        case CourtEnum.R2602:
            return 'Amtsgericht Hagen'
        case CourtEnum.K1101:
            return 'Amtsgericht Hamburg'
        case CourtEnum.R2404:
            return 'Amtsgericht Hamm'
        case CourtEnum.M1502:
            return 'Amtsgericht Hanau'
        case CourtEnum.P2305:
            return 'Amtsgericht Hannover'
        case CourtEnum.Y1109:
            return 'Amtsgericht Heilbad Heiligenstadt'
        case CourtEnum.Y1302:
            return 'Amtsgericht Hildburghausen'
        case CourtEnum.P2408:
            return 'Amtsgericht Hildesheim'
        case CourtEnum.D4501:
            return 'Amtsgericht Hof'
        case CourtEnum.V1122:
            return 'Amtsgericht Homburg'
        case CourtEnum.D5701:
            return 'Amtsgericht Ingolstadt'
        case CourtEnum.R2604:
            return 'Amtsgericht Iserlohn'
        case CourtEnum.Y1206:
            return 'Amtsgericht Jena'
        case CourtEnum.T3201:
            return 'Amtsgericht Kaiserslautern'
        case CourtEnum.M1607:
            return 'Amtsgericht Kassel'
        case CourtEnum.D2304:
            return 'Amtsgericht Kempten (Allgäu)'
        case CourtEnum.X1517:
            return 'Amtsgericht Kiel'
        case CourtEnum.R1304:
            return 'Amtsgericht Kleve'
        case CourtEnum.T2210:
            return 'Amtsgericht Koblenz'
        case CourtEnum.R3306:
            return 'Amtsgericht Köln'
        case CourtEnum.M1203:
            return 'Amtsgericht Königstein im Taunus'
        case CourtEnum.M1608:
            return 'Amtsgericht Korbach'
        case CourtEnum.R1402:
            return 'Amtsgericht Krefeld'
        case CourtEnum.T3304:
            return 'Amtsgericht Landau in der Pfalz'
        case CourtEnum.D2404:
            return 'Amtsgericht Landshut'
        case CourtEnum.V1125:
            return 'Amtsgericht Lebach'
        case CourtEnum.U1308:
            return 'Amtsgericht Leipzig'
        case CourtEnum.R2307:
            return 'Amtsgericht Lemgo'
        case CourtEnum.M1706:
            return 'Amtsgericht Limburg a. d. Lahn'
        case CourtEnum.X1721:
            return 'Amtsgericht Lübeck'
        case CourtEnum.T3104:
            return 'Amtsgericht Ludwigshafen am Rhein'
        case CourtEnum.P2507:
            return 'Amtsgericht Lüneburg'
        case CourtEnum.T2304:
            return 'Amtsgericht Mainz'
        case CourtEnum.B1601:
            return 'Amtsgericht Mannheim'
        case CourtEnum.B8535:
            return 'Amtsgericht Mannheim'
        case CourtEnum.M1809:
            return 'Amtsgericht Marburg'
        case CourtEnum.Y1304:
            return 'Amtsgericht Meiningen'
        case CourtEnum.D2505:
            return 'Amtsgericht Memmingen'
        case CourtEnum.V1127:
            return 'Amtsgericht Merzig'
        case CourtEnum.R1504:
            return 'Amtsgericht Mönchengladbach'
        case CourtEnum.T2214:
            return 'Amtsgericht Montabaur'
        case CourtEnum.Y1110:
            return 'Amtsgericht Mühlhausen'
        case CourtEnum.D2601:
            return 'Amtsgericht München'
        case CourtEnum.R2713:
            return 'Amtsgericht Münster'
        case CourtEnum.N1105:
            return 'Amtsgericht Neubrandenburg'
        case CourtEnum.V1120:
            return 'Amtsgericht Neunkirchen'
        case CourtEnum.G1309:
            return 'Amtsgericht Neuruppin'
        case CourtEnum.R1102:
            return 'Amtsgericht Neuss'
        case CourtEnum.Y1111:
            return 'Amtsgericht Nordhausen'
        case CourtEnum.D3310:
            return 'Amtsgericht Nürnberg'
        case CourtEnum.M1114:
            return 'Amtsgericht Offenbach am Main'
        case CourtEnum.P3210:
            return 'Amtsgericht Oldenburg (Oldenburg)'
        case CourtEnum.P3313:
            return 'Amtsgericht Osnabrück'
        case CourtEnum.V1124:
            return 'Amtsgericht Ottweiler'
        case CourtEnum.R2809:
            return 'Amtsgericht Paderborn'
        case CourtEnum.D2803:
            return 'Amtsgericht Passau'
        case CourtEnum.X1321:
            return 'Amtsgericht Pinneberg'
        case CourtEnum.Y1209:
            return 'Amtsgericht Pößneck'
        case CourtEnum.Y1208:
            return 'Amtsgericht Pößneck Zweigstelle Bad Lobenstein'
        case CourtEnum.G1312:
            return 'Amtsgericht Potsdam'
        case CourtEnum.R2204:
            return 'Amtsgericht Recklinghausen'
        case CourtEnum.D3410:
            return 'Amtsgericht Regensburg'
        case CourtEnum.N1206:
            return 'Amtsgericht Rostock'
        case CourtEnum.Y1210:
            return 'Amtsgericht Rudolstadt'
        case CourtEnum.V1109:
            return 'Amtsgericht Saarbrücken'
        case CourtEnum.V1121:
            return 'Amtsgericht Saarlouis'
        case CourtEnum.D4608:
            return 'Amtsgericht Schweinfurt'
        case CourtEnum.N1308:
            return 'Amtsgericht Schwerin'
        case CourtEnum.R3208:
            return 'Amtsgericht Siegburg'
        case CourtEnum.R2909:
            return 'Amtsgericht Siegen'
        case CourtEnum.Y1112:
            return 'Amtsgericht Sömmerda'
        case CourtEnum.Y1113:
            return 'Amtsgericht Sondershausen'
        case CourtEnum.Y1307:
            return 'Amtsgericht Sonneberg'
        case CourtEnum.V1123:
            return 'Amtsgericht St. Ingbert'
        case CourtEnum.V1126:
            return 'Amtsgericht St. Wendel'
        case CourtEnum.P2106:
            return 'Amtsgericht Stadthagen'
        case CourtEnum.Y1214:
            return 'Amtsgericht Stadtroda'
        case CourtEnum.R2706:
            return 'Amtsgericht Steinfurt'
        case CourtEnum.W1215:
            return 'Amtsgericht Stendal'
        case CourtEnum.N1209:
            return 'Amtsgericht Stralsund'
        case CourtEnum.D3413:
            return 'Amtsgericht Straubing'
        case CourtEnum.B2609:
            return 'Amtsgericht Stuttgart'
        case CourtEnum.B8534:
            return 'Amtsgericht Stuttgart'
        case CourtEnum.Y1308:
            return 'Amtsgericht Suhl'
        case CourtEnum.P2613:
            return 'Amtsgericht Tostedt'
        case CourtEnum.D2910:
            return 'Amtsgericht Traunstein'
        case CourtEnum.B2805:
            return 'Amtsgericht Ulm'
        case CourtEnum.B8537:
            return 'Amtsgericht Ulm'
        case CourtEnum.V1128:
            return 'Amtsgericht Völklingen'
        case CourtEnum.P2716:
            return 'Amtsgericht Walsrode'
        case CourtEnum.D3508:
            return 'Amtsgericht Weiden i.d.OPf.'
        case CourtEnum.Y1114:
            return 'Amtsgericht Weimar'
        case CourtEnum.M1710:
            return 'Amtsgericht Wetzlar'
        case CourtEnum.M1906:
            return 'Amtsgericht Wiesbaden'
        case CourtEnum.T2408:
            return 'Amtsgericht Wittlich'
        case CourtEnum.R1608:
            return 'Amtsgericht Wuppertal'
        case CourtEnum.D4708:
            return 'Amtsgericht Würzburg'
        case CourtEnum.T3403:
            return 'Amtsgericht Zweibrücken'
        default:
            return courtId
    }
}
