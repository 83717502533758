import {observer} from "mobx-react";
import {useEffect} from "react";
import {registrationStore} from "../../../../../../store";
import {FieldInput} from "../../../../../ui";
import {confirmationFormTextZBI, ConfirmationProps, isValid, verificationCodePattern} from "../../confirmation/ConfirmationFormCommons";

const ConfirmationFormHa = observer(function ConfirmationFormHa({
                                                                    dialogStore,
                                                                    value,
                                                                    onChange
                                                                }: ConfirmationProps) {
    const invalidZBI = !isValid(value.verificationCode, verificationCodePattern)

    useEffect(() => {
        dialogStore.primaryReady = isValid(value.verificationCode, verificationCodePattern)
    }, [value, dialogStore]);

    return <>
        <div style={{
            whiteSpace: 'pre-line'
        }}>
            {confirmationFormTextZBI}
        </div>
        <div style={{
            paddingTop: '1em',
            paddingBottom: '1em'
        }}>
            <label className={"label"}>Fahrzeug: {registrationStore.vin}</label>
        </div>
        <div className={"columns"}>

            <div className={"column is-full"}>
                <FieldInput
                    label={"ZBI Sicherheitscode"}
                    value={value.verificationCode}
                    onChange={v => {
                        onChange({...value, verificationCode: v})
                    }}
                    invalid={invalidZBI}
                    required={true}
                    disabled={dialogStore.primaryExecuting}
                    maxLength={7}
                    help={!value.verificationCode ? 'Pflichtfeld' : `7-stellige Zahlen-Buchstaben-Kombination`}
                />
            </div>
        </div>
    </>
})

export {
    ConfirmationFormHa
}
