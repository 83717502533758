import {makeAutoObservable} from "mobx";
import {
    api,
    catchApi,
    GetRequestAuditDocumentParamsEnum,
    RegistrationRequestAuditDto,
    RegistrationStatusDto
} from "../api";
import {registrationStore} from "./RegistrationStore";

class RegistrationRequestHistoryStore {
    get data(): RegistrationRequestAuditDto[] {
        return this._data;
    }

    set data(value: RegistrationRequestAuditDto[]) {
        this._data = value;
    }

    private _data: RegistrationRequestAuditDto[] = []
    private _id: string | undefined

    constructor() {
        makeAutoObservable(this)
    }

    async load(id: string) {
        this._id = id
        await catchApi(async () => {
            const response = await api.v1.getRequestAuditEntries(id)
            this.data = response.data
        })
    }

    async triggerRefresh(status: RegistrationStatusDto) {
        const id = status.id
        const modificationDateUnchanged = registrationStore.data?.mostRecentModificationDate === status.mostRecentModificationDate
        if (modificationDateUnchanged) {
            return
        }
        if (this._id === id) {
            await this.load(id)
        }
    }

    async download(dto: RegistrationRequestAuditDto, type: GetRequestAuditDocumentParamsEnum): Promise<Blob | undefined> {
        return await catchApi(async () => {
            const result = await api.v1.getRequestAuditDocument(
                dto.registrationId,
                dto.requestId,
                type,
                {
                    format: "blob"
                }
            )
            return await result.blob()
        })
    }
}

const registrationRequestHistoryStore = new RegistrationRequestHistoryStore()

export {
    registrationRequestHistoryStore
}
