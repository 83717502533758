import {observer} from "mobx-react";
import {Modal} from "./Modal";
import {DialogStore} from "../../store";
import "./Dialog.scss"
import {ReactNode} from "react";

interface DialogProps {
    id?: string
    title: ReactNode
    children?: ReactNode
    extraButton?: ReactNode
    primaryAction?: () => Promise<boolean | string | null>
    primaryActionTitle?: string
    store: DialogStore
    titleCloseable?: boolean
    disabledBodyPadding?: boolean
}

const Dialog = observer(function Dialog({
                                            id,
                                            title,
                                            children,
                                            primaryAction,
                                            primaryActionTitle,
                                            titleCloseable,
                                            extraButton,
                                            store,
                                            disabledBodyPadding,
                                        }: DialogProps) {
    return <Modal store={store.modalStore}>
        <div id={id} className="modal-card">
            <header className="modal-card-head">
                <div className="modal-card-title">{title}</div>
                {titleCloseable === false ? <></> : <button className="delete" aria-label="close" onClick={() => {
                    store.close()
                }}></button>}
            </header>
            <section className="modal-card-body" style={{padding: disabledBodyPadding ? '0' : ''}}>
                {children}
            </section>
            {store.errorMessage && <section style={{
                backgroundColor: 'var(--ct-danger-color)',
                color: 'white',
                padding: '1em var(--bulma-modal-card-body-padding)'
            }}>{store.errorMessage}</section>}
            <footer className="modal-card-foot">
                <div className="buttons">
                    {extraButton}
                    {primaryAction && <button
                        id={'modal-button-primary'}
                        className={`button is-primary ${store.primaryExecuting ? 'is-loading' : ''}`}
                        disabled={store.primaryReady === false}
                        onClick={async () => {
                            store.primaryExecuting = true
                            try {
                                const success = await primaryAction()
                                if (success) {
                                    store.close()
                                }
                            } catch (e: any) {
                                console.log(e)
                            } finally {
                                store.primaryExecuting = false
                            }
                        }}>{primaryActionTitle || "OK"}</button>}
                    <button disabled={store.primaryExecuting} className="button" onClick={() => {
                        store.close()
                    }}>Abbrechen
                    </button>
                </div>
            </footer>
        </div>
    </Modal>
})

export {
    Dialog
}
