import {makeAutoObservable} from "mobx";
import {
    api,
    catchApi,
    DeliveryInformationDtoDeliveryDocumentTypeEnum,
    DeliveryInformationDtoDeliveryServiceTypeEnum,
    OrganizationDto,
    RegistrationDtoDataDocumentStatusEnum,
    RegistrationDtoPoaDocumentStatusEnum,
    RegistrationDtoSepaDocumentStatusEnum,
    RegistrationDtoServiceTypeEnum
} from "../api";
import {SelectOption} from "../components/ui/OrganizationSelect";
import {mapOrganizationDtoToSelectOption} from "./RegistrationUtils";

class CreateRegistrationStore {
    get serviceType(): RegistrationDtoServiceTypeEnum {
        return this._serviceType;
    }

    set serviceType(value: RegistrationDtoServiceTypeEnum) {
        this._serviceType = value;
    }

    get organization(): SelectOption<OrganizationDto> | undefined {
        const orgId = this._organizationId
        if (orgId) {
            const el = this.allOrganizations.find(e => e.id === orgId)
            return el ? mapOrganizationDtoToSelectOption(el) : undefined
        }
        return undefined;
    }

    set organization(value: SelectOption<OrganizationDto> | undefined) {
        this._organizationId = value && value.value ? value.value.id : undefined
    }

    get allOrganizations(): OrganizationDto[] {
        return this._allOrganizations;
    }

    set allOrganizations(value: OrganizationDto[]) {
        this._allOrganizations = value;
    }

    get ready(): boolean {
        if (this.allOrganizations.length > 0 && !this.organization) {
            return false
        }
        return Boolean(this.serviceType)
    }

    private _allOrganizations: OrganizationDto[] = []
    private _serviceType: RegistrationDtoServiceTypeEnum = RegistrationDtoServiceTypeEnum.AB
    private _organizationId: string | undefined

    constructor() {
        makeAutoObservable(this)
    }

    loadOrganizations() {
        catchApi(async () => {
            const organizationResponse = await api.v1.listOrganizations()
            this.allOrganizations = organizationResponse.data || []
            if (this.allOrganizations.length === 1) {
                this.organization = mapOrganizationDtoToSelectOption(this.allOrganizations[0])
            }
        }).catch(() => {
            this.allOrganizations = []
        })
    }

    reset() {
        this.loadOrganizations()
        this._organizationId = undefined
        this._serviceType = RegistrationDtoServiceTypeEnum.AB
    }

    async save() {
        const serviceType = this.serviceType
        const organizationId = this._organizationId
        return await catchApi(async () => {
            let deliveryInformation =
                serviceType === RegistrationDtoServiceTypeEnum.AB ? [] :
                    [{
                        deliveryServiceType: DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_KFZ_REGISTRATION_SERVICE,
                        deliveryDocumentType: DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL
                    }];
            const response = await api.v1.addRegistration({
                serviceType: serviceType,
                archived: false,
                deregistered: serviceType === RegistrationDtoServiceTypeEnum.WZ ? true : undefined,
                dataDocumentStatus: RegistrationDtoDataDocumentStatusEnum.NOT_SET,
                fineDustBadge: false,
                poaDocumentStatus: RegistrationDtoPoaDocumentStatusEnum.NOT_SET,
                previousLicensePlateReservation: false,
                selfRegistration: false,
                sepaDocumentStatus: RegistrationDtoSepaDocumentStatusEnum.NOT_SET,
                holder: {corporate: false},
                deliveryInformation: deliveryInformation,
                organizationId: organizationId
            })
            return response.data
        })
    }

    async searchOrganization(value: string): Promise<SelectOption<OrganizationDto>[]> {
        return await catchApi(async () => {
            const response = await api.v1.listOrganizations()
            const data = response.data
            const v = value.toLowerCase()
            return data.filter(o => {
                return o.id === v || o.costCenter === v || o.name.toLowerCase().split(v).length > 1
            }).map(mapOrganizationDtoToSelectOption)
        }) || []

    }
}

const createRegistrationStore = new CreateRegistrationStore()

export {
    createRegistrationStore
}
