import {observer} from "mobx-react";
import {
    orderType,
    RegistrationDtoServiceTypeEnum,
    RegistrationOfficePortalAvailabilityDtoStatusEnum
} from "../../../../api";
import {DialogStore, registrationOfficePortalAvailabilityStore, registrationStore} from "../../../../store";
import {Dialog} from "../../../ui";
import {getConfirmationElement} from "./RegistrationDetailHelper";
import {useState} from "react";

const RegistrationDetailConfirmDialog = observer(function RegistrationDetailConfirmDialog({store}: {
    store: DialogStore
}) {
    const ConfirmElement = getConfirmationElement()
    const [forceSubmit, setForceSubmit] = useState(false)

    store.onClose = () => {
        setForceSubmit(false)
    }

    function subscribeToPortalSocketEvent() {
        registrationOfficePortalAvailabilityStore.subscribeTo(registrationStore.id!!, async (dto) => {
            store.primaryExecuting = false
            if (dto.status === RegistrationOfficePortalAvailabilityDtoStatusEnum.PORTAL_FOR_REGISTRATION_OFFICE_AVAILABLE) {
                await registrationStore.submit()
                store.close()
            } else {
                store.errorMessage = "Zur angegebenen Zulassungsbehörde konnte kein i-Kfz-Portal ermittelt werden."
                setForceSubmit(true)
            }
        })
    }

    const DefaultSubmitButton = <button
        className={`button is-primary ${store.primaryExecuting ? 'is-loading' : ''}`}
        disabled={store.primaryReady === false}
        onClick={async () => {
            try {
                store.primaryExecuting = true
                registrationStore.submitDto = store.data

                if (registrationStore.serviceType === RegistrationDtoServiceTypeEnum.AB) {
                    await registrationStore.submit()
                    store.primaryExecuting = false
                    store.close()
                } else {
                    subscribeToPortalSocketEvent()
                    await registrationStore.preSubmit()
                }
            } catch (e: any) {
                const errorData = await e.json()
                const message = errorData.message
                if (message) {
                    store.errorMessage = message
                }else if (errorData.errors && errorData.errors.length) {
                    store.errorMessage = errorData.errors.join("\n")
                }
                store.primaryExecuting = false
            }
        }}
    >Beantragen</button>

    const ForceSubmitButton = <button
        className={`button is-danger ${store.primaryExecuting ? 'is-loading' : ''}`}
        disabled={store.primaryReady === false}
        onClick={async () => {
            store.primaryExecuting = true
            await registrationStore.submit()
            store.primaryExecuting = false
            store.close()
        }}
    >Vorgang trotzdem beantragen</button>

    return <>
        <Dialog
            titleCloseable={false}
            title={<>
                {orderType(registrationStore.serviceType!!)}
                <span className="material-symbols-outlined" style={{
                    paddingLeft: '0.2em',
                    color: 'var(--ct-success-color-bright)'
                }}>check_circle</span>
                <span style={{
                    paddingLeft: '0.2em',
                    fontWeight: '700'
                }}>{registrationStore.licensePlateDisplay}</span>
            </>}
            extraButton={
                forceSubmit ? ForceSubmitButton : DefaultSubmitButton
            }
            store={store}
        >
            <ConfirmElement
                dialogStore={store}
                value={store.data}
                onChange={v => {
                    store.data = v
                }}/>
        </Dialog>
    </>
})

export {
    RegistrationDetailConfirmDialog
}
