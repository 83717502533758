import {observer} from "mobx-react";

import "../detail/RegistratonDetailPage.scss"
import "./RegistrationOverviewFilter.scss"
import {
    orderStatus,
    orderType,
    RegistrationListItemDtoOrderStatusEnum,
    RegistrationListItemDtoOrderTypeEnum,
} from "../../../../api";
import {ArchiveStatus, registrationOverviewFilterStore,} from "../../../../store";
import {useSearchParams} from "react-router-dom";
import {OrganizationSelect, SelectOption} from "../../../ui/OrganizationSelect";

const serviceTypes: RegistrationListItemDtoOrderTypeEnum[] = [
    RegistrationListItemDtoOrderTypeEnum.NZ,
    RegistrationListItemDtoOrderTypeEnum.WZ,
    RegistrationListItemDtoOrderTypeEnum.UM,
    RegistrationListItemDtoOrderTypeEnum.UO,
    RegistrationListItemDtoOrderTypeEnum.UI,
    RegistrationListItemDtoOrderTypeEnum.AB,
    RegistrationListItemDtoOrderTypeEnum.TZ,
    RegistrationListItemDtoOrderTypeEnum.HA,
]

const status: RegistrationListItemDtoOrderStatusEnum[] = [
    RegistrationListItemDtoOrderStatusEnum.CREATED,
    RegistrationListItemDtoOrderStatusEnum.SUBMITTED,
    RegistrationListItemDtoOrderStatusEnum.SUCCESS,
    RegistrationListItemDtoOrderStatusEnum.FINISHED,
    RegistrationListItemDtoOrderStatusEnum.SUBMISSION_FAILED,
    RegistrationListItemDtoOrderStatusEnum.FAILED,
]

function updateServiceType(type: RegistrationListItemDtoOrderTypeEnum, checked: boolean) {
    if (checked) {
        registrationOverviewFilterStore.addSelectedServiceType(type)
    } else {
        registrationOverviewFilterStore.removeSelectedServiceType(type)
    }
}

function updateStatus(type: RegistrationListItemDtoOrderStatusEnum, checked: boolean) {
    if (checked) {
        registrationOverviewFilterStore.addSelectedStatus(type)
    } else {
        registrationOverviewFilterStore.removeSelectedStatus(type)
    }
}

const Form = observer(function (){
    return <>
        <div className="form-row">
            <div className="label">Vorgangsarten:</div>
            {serviceTypes.map(type => {
                return (
                    <div key={type}>
                        <label className="checkbox">
                            <input type="checkbox"
                                   checked={registrationOverviewFilterStore.selectedServiceTypes.indexOf(type) > -1}
                                   onChange={(e) => updateServiceType(type, e.target.checked)}/>
                            {orderType(type)}
                        </label>
                    </div>)
            })}

        </div>
        <div className="form-row">
            <div className="label">Status:</div>
            {status.map(status => {
                return (
                    <div key={status}>
                        <label className="checkbox">
                            <input type="checkbox"
                                   checked={registrationOverviewFilterStore.selectedStatus.indexOf(status) > -1}
                                   onChange={(e) => updateStatus(status, e.target.checked)}/>
                            {orderStatus(status)}
                        </label>
                    </div>
                )
            })}

        </div>
        <div className="form-row">
            <div className="label">Zeitraum:</div>
            <div className="field">
                <label className="label">Von</label>
                <div className="control">
                    <input className="input is-fullwidth" type="date"
                           value={registrationOverviewFilterStore.from}
                           onChange={(e) => registrationOverviewFilterStore.from = e.target.value}
                    />
                </div>
            </div>
            <div className="field">
                <label className="label">Bis</label>
                <div className="control">
                    <input className="input is-fullwidth" type="date"
                           value={registrationOverviewFilterStore.to}
                           onChange={(e) => registrationOverviewFilterStore.to = e.target.value}
                    />
                </div>
            </div>
        </div>
        <div className="form-row">
            <div className="label">Standort:</div>
            <OrganizationSelect
                value={registrationOverviewFilterStore.organization as SelectOption<any>}
                options={registrationOverviewFilterStore.allOrganizations.map(e => ({label: e.name, value: e}) )}
                search={async v => {
                    return await registrationOverviewFilterStore.searchOrganization(v)
                }}
                onChange={v => {
                    registrationOverviewFilterStore.organization = v
                }}
            />
        </div>

        <div className="form-row">
            <div className="label">Archiviert:</div>
            <div className="control">
                <div>
                    <label className="radio">
                        <input type="radio" name="archived"
                               checked={registrationOverviewFilterStore.archived === ArchiveStatus.HIDE_ARCHIVED}
                               onChange={(e) => {
                                   registrationOverviewFilterStore.archived = ArchiveStatus.HIDE_ARCHIVED
                               }}
                        />
                        Ausblenden
                    </label>
                </div>
                <div>
                    <label className="radio">
                        <input type="radio" name="archived"
                               checked={registrationOverviewFilterStore.archived === ArchiveStatus.SHOW_ARCHIVED}
                               onChange={(e) => {
                                   registrationOverviewFilterStore.archived = ArchiveStatus.SHOW_ARCHIVED
                               }}
                        />
                        Einblenden
                    </label>
                </div>
                <div>
                    <label className="radio">
                        <input type="radio" name="archived"
                               checked={registrationOverviewFilterStore.archived === ArchiveStatus.SHOW_ARCHIVED_ONLY}
                               onChange={(e) => {
                                   registrationOverviewFilterStore.archived = ArchiveStatus.SHOW_ARCHIVED_ONLY
                               }}
                        />
                        Nur archivierte Vorgänge
                    </label>
                </div>
            </div>
        </div>
    </>
})

const FormButtons = observer(function (){
    const [, setSearchParams] = useSearchParams();

    function applyFilter() {
        setSearchParams((sp) => {
            sp.delete("types")
            sp.delete("status")
            sp.delete("from")
            sp.delete("to")
            sp.delete("archived")
            sp.delete("organisationId")
            if (registrationOverviewFilterStore.selectedServiceTypes.length > 0) {
                registrationOverviewFilterStore.selectedServiceTypes.forEach(type => {
                    sp.append("types", type)
                })
            }
            if (registrationOverviewFilterStore.selectedStatus.length > 0) {
                registrationOverviewFilterStore.selectedStatus.forEach(status => {
                    sp.append("status", status)
                })
            }
            if (registrationOverviewFilterStore.from) {
                sp.set("from", registrationOverviewFilterStore.from)
            }
            if (registrationOverviewFilterStore.to) {
                sp.set("to", registrationOverviewFilterStore.to)
            }
            if(registrationOverviewFilterStore.archived !== ArchiveStatus.HIDE_ARCHIVED){
                sp.set("archived", registrationOverviewFilterStore.archived)
            }
            if (registrationOverviewFilterStore.organizationId) {
                sp.set("organisationId", registrationOverviewFilterStore.organizationId)
            }
            //reset the page so that we jump to page 1
            sp.delete("page")
            return sp
        })
        registrationOverviewFilterStore.visible = false
    }


    function resetFilter() {
        registrationOverviewFilterStore.selectedServiceTypes = []
        registrationOverviewFilterStore.selectedStatus = []
        registrationOverviewFilterStore.from = ""
        registrationOverviewFilterStore.to = ""
        registrationOverviewFilterStore.archived = ArchiveStatus.HIDE_ARCHIVED
        registrationOverviewFilterStore.organizationId = undefined
        applyFilter()
    }
    return <>
        <div className="button-row">
            <button title="Filter zurücksetzen" className="button is-danger" onClick={(e) => resetFilter()}>
                <span className="material-symbols-outlined">delete_sweep</span>
            </button>
            <button title="Filter anwenden" className="button is-primary is-fullwidth"
                    onClick={(e) => applyFilter()}>Filter anwenden
            </button>
        </div>
    </>
})

const RegistrationOverviewFilter = observer(function RegistrationOverviewFilter() {
    return <div className="registration-overview-filter">
        <div className="form-area">
            <Form></Form>
        </div>
        <div className="button-area">
            <FormButtons></FormButtons>
        </div>

    </div>
})

export {
    RegistrationOverviewFilter
}
