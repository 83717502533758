import {observer} from "mobx-react";
import {FocusEventHandler, useState} from "react";

import "./AutoComplete.scss"

interface AutoCompleteProps<T> {
    optionsHandler: (value: string) => Promise<T>

    onSelect: (value: AutoCompleteOption<T> | undefined) => any
    onBlur?: FocusEventHandler<any> | undefined

    value?: AutoCompleteOption<T>
}

interface AutoCompleteOption<T> {
    label: string,
    value: T,
}

const AutoComplete = observer(function AutoComplete({
                                            optionsHandler, onSelect, value, onBlur
                                        }: AutoCompleteProps<any>) {
    const [options, setOptions] = useState<AutoCompleteOption<any>[]>([])

    async function update(value: string) {
        let result = await optionsHandler(value)
        setOptions(result)
    }

    return <div style={{flex: 1}} className={"autocomplete dropdown is-active"}>
        <div className="dropdown-trigger" aria-haspopup={"true"} aria-controls={"optionsMenu"}>
            <div className={"field"}>
            {
                value
                    ?
                    <div className="control has-icons-right" onClick={(e) => onSelect(undefined)}>
                        <input className={"input is-clickable"} value={value.label}/>
                        <span className="icon is-small is-right is-clickable">
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </span>
                    </div>
                    :
                    <div className="control has-icons-right">
                        <input onBlur={onBlur} className={"input is-clickable"} onChange={(e) => update(e.target.value)}/>
                    </div>
            }
            </div>
        </div>
        {value || options.length === 0 ? <></> :
            <div className={"dropdown-menu "} id={"optionsMenu"} role={"menu"} >
                <div className={"dropdown-content options"}>
                    {options.map(option => (
                            <div className={"dropdown-item is-clickable"} onClick={(value) => onSelect(option)}>
                                <label className={"is-clickable"}> {option.label} </label>
                            </div>
                        )
                    )}
                </div>
            </div>
        }
    </div>
})

export {
    AutoComplete
}
