import {observer} from "mobx-react";

import "./Pager.scss"
import {useSearchParams} from "react-router-dom";

interface PaginationProps {
    total: number
}

const Pager = observer(function Pager({
                                     total
                                 }: PaginationProps) {
    const [searchParams, setSearchParams] = useSearchParams();

    const current = parseInt(searchParams.get("page") || "1", 10)
    const size = parseInt(searchParams.get("size") || "25", 10)

    searchParams.get("search")

    if(!total){
        return <></>
    }
    return <div className={"pager"}>
        <div className={"buffer"}>

        </div>
        <div className={"paging-wrapper"}>
            <div className={"button-wrapper"}>
                <button
                    onClick={() => {
                        searchParams.set("page", `${current - 1}`)
                        setSearchParams(searchParams)
                    }}
                    className={"button is-small is-primary is-disabled"}
                    disabled={current === 1}>
                    <span className="material-symbols-outlined">arrow_back</span>
                </button>
            </div>
            <div className={"status"}>
                <label className={"label"}>{current}/{total}</label>
            </div>
            <div className={"button-wrapper"}>
                <button
                    onClick={() => {
                        searchParams.set("page", `${current + 1}`)
                        setSearchParams(searchParams)
                    }}
                    className={"button is-small is-primary"}
                    disabled={current === total}>
                    <span className="material-symbols-outlined">arrow_forward</span>
                </button>
            </div>
        </div>
        <div className={"buffer page-size"}>
            <div className="select is-small">
                <select value={size} onChange={e => {
                    searchParams.set("size", e.target.value)
                    setSearchParams(searchParams)
                }}>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                    <option>200</option>
                </select>
            </div>
        </div>
    </div>
})

export {
    Pager
}
