import {observer} from "mobx-react";
import {nextId} from "../../utils";

import "./CitySelect.scss";
import {api, catchApi} from "../../api";
import {FocusEventHandler, useState} from "react";

interface Selection {
    zip: string
    city: string
}

interface CitySelectProps {
    id?: string
    zip?: string
    city?: string
    required?: boolean
    invalid?: boolean
    disabled?: boolean
    help?: string
    onBlur?: FocusEventHandler<any> | undefined
    onChange: (value: Selection | undefined) => void
}

async function listCities(value: string) {
    return await catchApi(async () => {
        const cityResult = await api.v1.listCities({zip: value})
        return cityResult.data
    })
}

const CitySelect = observer(function CitySelect({id, onChange, onBlur, city, zip, required, invalid, help, disabled}: CitySelectProps) {
    const [plzState, setPlzState] = useState<string | undefined>()
    const [cities, setCities] = useState<string[]>([])
    const genId = nextId()
    const idUsed = id || `el-${genId}`

    return <>
        <div id={id} className={`city-select field`}>
            <div>
                <div className={`field-zipcode field ${required ? 'required' : ''}`}>
                    <label htmlFor={`${idUsed}-input`} className="label">Postleitzahl</label>
                    <div className={"control zipcode"}>
                        <input
                            disabled={disabled}
                            maxLength={5}
                            type={"text"}
                            id={`${idUsed}-input`}
                            aria-autocomplete={"none"}
                            className={`input ${invalid ? 'is-danger' : ''}`}
                            defaultValue={zip||''}
                            onBlur={onBlur}
                            onChange={async e=> {
                                const value = e.target.value
                                if (value && value.length === 5) {
                                    setPlzState(value)
                                    const data = await listCities(value)
                                    if (data && data.length  === 1 && data[0].zip === value) {
                                        const names = data[0].cityNames
                                        setCities(names)
                                        if (names.length === 1) {
                                            onChange({
                                                zip: value,
                                                city: names[0]
                                            })
                                        }
                                    }
                                } else {
                                    setCities([])
                                    setPlzState(undefined)
                                    onChange(undefined)
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={`field-city field ${required ? 'required' : ''}`}>
                    <label htmlFor={`${idUsed}-input`} className="label">Stadt</label>
                    <div className={"control is-expanded"}>
                        <div className={"select is-fullwidth"}>
                            <select onBlur={onBlur} disabled={disabled} className={`select ${invalid ? 'is-danger' : ''}`} onChange={e => {
                                const selected = e.target.value
                                if (selected) {
                                    onChange({
                                        city: selected,
                                        zip: plzState!!
                                    })
                                } else {
                                    onChange(undefined)
                                }
                            }}>
                                {(!cities || !cities.length) && city ? <option>{city}</option>: <></>}
                                {cities && cities.length > 1 ? <option></option>: <></>}
                                {cities ? cities.map(city => <option key={city}>{city}</option>) : <></>}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <p className={`help ${invalid ? 'is-danger' : ''}`}>{help}</p>
        </div>
    </>
})

export {
    CitySelect
}
