import React, {FocusEventHandler} from "react";

interface FieldSelectProps {
    id?: string
    disabled?: boolean
    label?: string
    value?: string
    required?: boolean
    invalid?: boolean
    help?: string
    onChange?: (value: string) => void
    onBlur?: FocusEventHandler<any> | undefined
    options: {
        value: string,
        label: string
    }[]
}

function FieldSelect({
                         id,
                         disabled,
                         label,
                         value,
                         required,
                         invalid,
                         help,
                         onChange,
                         onBlur,
                         options
                     }: FieldSelectProps) {
    return <>
        <div id={id} className={`field  ${required ? 'required' : ''}`}>
            <label className="label">{label}</label>
            <div className="control">
                <div className={`select is-fullwidth ${invalid ? 'is-danger' : ''}`}>
                    <select
                        id={id?`${id}-select`:undefined}
                        disabled={disabled}
                        value={value}
                        className={`select ${invalid ? 'is-danger' : ''}`}
                        onBlur={onBlur}
                        onChange={e => {
                            onChange && onChange(e.target.value)
                        }}>
                        {value ? <></> : <option value={''}></option>}
                        {options.map(e => <option key={e.value} value={e.value}>{e.label}</option>)}
                    </select>
                </div>
            </div>
            <p className={`help ${invalid ? 'is-danger' : ''}`}>{help}</p>
        </div>
    </>
}

export {
    FieldSelect
}
