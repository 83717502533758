import {makeAutoObservable} from "mobx";
import {api, catchApi, ListAvailableDocumentsEnum} from "../api";

class RegistrationDocumentStore {
    get documents(): ListAvailableDocumentsEnum[] {
        return this._documents;
    }

    set documents(value: ListAvailableDocumentsEnum[]) {
        this._documents = value;
    }

    private _documents: ListAvailableDocumentsEnum[] = []

    constructor() {
        makeAutoObservable(this)
    }

    clear() {
        this.documents = []
    }

    async load(registrationId: string | undefined) {
        if (!registrationId) {
            this.clear()
            return
        }
        await catchApi(async () => {
            const response = await api.v1.listAvailableDocuments(registrationId)
            this.documents = response.data
        })
    }
}

const registrationDocumentStore = new RegistrationDocumentStore()

export {
    registrationDocumentStore
}
