import {observer} from "mobx-react";
import "./Scroller.scss"
import {ReactNode} from "react";

interface ScrollerProps {
    children: ReactNode
}

const Scroller = observer(function Scroller({children}: ScrollerProps) {
    return <div className={"scroller"}>
        <div className={"scroll-content"}>
            {children}
        </div>
    </div>
})

export {
    Scroller
}
