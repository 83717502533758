import {observer} from "mobx-react";
import * as yup from "yup";
import {useFormik} from "formik";
import {orderType, RegistrationDtoServiceTypeEnum} from "../../api";
import {featureStore} from "../../store";
import {FocusEventHandler} from "react";

interface ProcessTypeSelectProps {
    id?: string
    label?: string | undefined
    required?: boolean | undefined
    disabled?: boolean
    value?: RegistrationDtoServiceTypeEnum | undefined
    onBlur?: FocusEventHandler<any> | undefined
    onSelect: (type: RegistrationDtoServiceTypeEnum) => void
}

const ServiceTypeSelect = observer(function ServiceTypeSelect({id, value, onSelect, onBlur, required, label, disabled}: ProcessTypeSelectProps) {
    const possibleServiceTypes = Object.entries(RegistrationDtoServiceTypeEnum)
        .filter(([_key, value]) =>
            value !== RegistrationDtoServiceTypeEnum.HA || (value === RegistrationDtoServiceTypeEnum.HA && featureStore.isActive("HA")))

    const s = possibleServiceTypes.map((o) => {
        return o[0]
    }).join('|')

    const message = 'Bitte wähle eine Vorgangsart'
    const fieldValidation = yup
        .string()
        .matches(new RegExp(`^${s}$`), message)

    const validationSchema = yup.object({
        serviceType: required ? fieldValidation.required("Vorgangsart ist erforderlich") : fieldValidation,
    });
    const formik = useFormik({
        initialValues: {
            serviceType: value,
        },
        validationSchema: validationSchema,
        onSubmit: () => {},
    });

    const serviceTypeError = formik.touched.serviceType && Boolean(formik.errors.serviceType)

    return <>
        <div className="field service-type-select" id={id}>
            {label ? <label className="label">{label}</label> : <></>}
            <div className="control">
                <div className={`select ${serviceTypeError ? "is-danger" : ""}`} style={{display: "flex"}}>
                    <select
                        id={id?`${id}-select`:undefined}
                        disabled={disabled}
                        style={{flex: "1"}}
                        value={formik.values.serviceType}
                        name={"serviceType"}
                        onChange={(e) => {
                            formik.handleChange(e)
                            onSelect(e.currentTarget.value as RegistrationDtoServiceTypeEnum)
                        }}
                        onBlur={e => {
                            formik.handleBlur(e)
                            onBlur && onBlur(e)
                        }}
                    >
                        {possibleServiceTypes.map((o) => {
                            return <option key={o[0]} value={o[0]}>{orderType(o[1])}</option>
                        })}
                    </select>
                </div>
                <p className={`help ${serviceTypeError ? "is-danger" : ""}`}></p>
            </div>
        </div>
    </>
})

export {
    ServiceTypeSelect
}
