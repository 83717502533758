import {makeAutoObservable} from "mobx";
import {User} from "oidc-client-ts"
import {AuthContextProps} from "react-oidc-context";
import {oidc, page} from "../config"
import {socketStore} from "./SocketStore";
import {tenantSelectStore} from "./TenantSelectStore";
import {featureStore} from "./FeatureStore";


export interface JWT {
    realm_access: { roles: string[] }
    tenantId?: string | undefined
}

export function getJwt(token: string): JWT {
    return JSON.parse(atob(token.split('.')[1])) as JWT
}

class AuthStore {
    get auth(): AuthContextProps | undefined {
        return this._auth;
    }

    get accessToken(): string | undefined {
        return this.auth?.user?.access_token
    }

    set auth(value: AuthContextProps | undefined) {
        this._auth = value;
        if (value?.user?.access_token) {
            tenantSelectStore.updateToken(value?.user?.access_token)
            featureStore.load()
            socketStore.build(value?.user?.access_token)
        }
    }

    get isAdmin(): boolean {
        const accessToken = this._auth?.user?.access_token
        if (accessToken) {
            const jwt = getJwt(accessToken)
            if (jwt) {
                return jwt.realm_access.roles.indexOf("superadmin") !== -1 || jwt.realm_access.roles.indexOf("admin") !== -1
            }

        }
        return false;
    }

    private _auth: AuthContextProps | undefined = undefined;
    constructor() {
        makeAutoObservable(this)
    }

    get oidcStorage(): string | null {
        const oidcStorage = localStorage.getItem(`oidc.user:${oidc.config.authority}:${oidc.config.client_id}`)
        if (!oidcStorage) {
            return null;
        }
        return oidcStorage;
    }

    get user(): User | undefined {
        const oidcStorage = this.oidcStorage
        if (oidcStorage !== null) {
            return User.fromStorageString(oidcStorage)
        }
        return undefined
    }

    get initials(): string {
        const user = this.user
        if (user) {

            const familyName = user.profile.family_name
            const givenName = user.profile.given_name
            if (!familyName && !givenName) {
                return user.profile.sub.substring(0, 2)
            }
            if (givenName && familyName) {
                return `${familyName.substring(0, 1) || ' -'} ${givenName.substring(0, 1) || ''}`
            }
        }
        return 'U'
    }

    get name() : string | undefined {
        const user = this.user
        if (user) {

            const familyName = user.profile.family_name
            const givenName = user.profile.given_name
            if (givenName && familyName) {
                return `${givenName} ${familyName}`
            }
        }
        return undefined
    }

    get accountAddress(): string | undefined {
        if (oidc?.config?.authority) {
            const referrerUri = encodeURIComponent(window.location.href)
            return `${oidc?.config?.authority}/account/?referrer=${oidc.config.client_id}&referrer_uri=${referrerUri}`
        }
        return oidc?.config?.authority as string || undefined
    }

    clear() {
        this.auth?.removeUser()
        oidc.userStateStore.getAllKeys().then(keys => {
            keys.forEach(key => {
                oidc.userStateStore.remove(key)
            })
        })
    }

    logout() {
        this.clear()
        this.auth?.signoutRedirect({
            post_logout_redirect_uri: page.baseUrl
        })
    }
}

const authStore = new AuthStore()

export {
    authStore
}
