import React, {FocusEventHandler} from "react";

interface CheckboxProps {
    id?: string
    label?: string
    value?: boolean
    invalid?: boolean
    help?: string
    disabled?: boolean,
    onChange?: (value: boolean) => void
    onBlur?: FocusEventHandler<any> | undefined
}

function Checkbox({
                      id,
                      label,
                      value,
                      invalid,
                      help,
                      disabled,
                      onChange,
                      onBlur,
                  }: CheckboxProps) {
    return <div id={id}>
        <label className="checkbox" style={{userSelect: 'none'}}>
            <div className="control">
                <input
                    type="checkbox"
                    checked={value}
                    onBlur={onBlur}
                    disabled={disabled}
                    onChange={e => onChange && onChange(e.currentTarget.checked)}
                />
                {label}
            </div>
        </label>
        <p className={`help ${invalid ? 'is-danger' : ''}`}>{help}</p>
    </div>
}

export {
    Checkbox
}
