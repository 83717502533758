import {observer} from "mobx-react";

import "./ScrollTable.scss"
import {ReactNode} from "react";

interface ScrollTableProps {
    children: ReactNode
}


const ScrollTable = observer(function ScrollTable({children}: ScrollTableProps) {
    return <div className="scroll-table">
        <div className="scroller">
            <table className="table is-fullwidth is-hoverable is-striped">
                {children}
            </table>
        </div>
    </div>
})

export {
    ScrollTable
}
