import {observer} from "mobx-react";
import {Navbar} from "../ui";
import {useParams} from "react-router-dom";
import {organization} from "../page"

const OrganizationApp = observer(function OrganizationApp() {
    const params = useParams()
    return <div className={"app-wrapper"}>
        <Navbar />
        {params.id?<organization.OrganizationDetailPage />:<organization.OrganizationOverviewPage />}
    </div>
})

export {
    OrganizationApp
}
