import {observer} from "mobx-react";
import {useEffect} from "react";
import {registrationStore} from "../../../../../../store";
import {FieldInput} from "../../../../../ui";
import {
    confirmationFormTextZBII,
    ConfirmationProps,
    isValid,
    securityCodePattern,
    verificationCodePattern
} from "../../confirmation/ConfirmationFormCommons";

const ConfirmationFormWz = observer(function ConfirmationFormNz({
                                                                    dialogStore,
                                                                    value,
                                                                    onChange
                                                                }: ConfirmationProps) {
    const invalidZBII = !isValid(value.securityCode, securityCodePattern)
    const invalidZBI = !isValid(value.verificationCode, verificationCodePattern)

    useEffect(() => {
        dialogStore.primaryReady = isValid(value.securityCode, securityCodePattern) && isValid(value.verificationCode, verificationCodePattern)
    }, [value, dialogStore]);

    return <>
        <div style={{
            whiteSpace: 'pre-line'
        }}>
            {confirmationFormTextZBII}
        </div>
        <div style={{
            paddingTop: '1em',
            paddingBottom: '1em'
        }}>
            <label className={"label"}>Fahrzeug: {registrationStore.vin}</label>
        </div>
        <div className={"columns"}>
            <div className={"column is-half"}>
                <FieldInput
                    label={"ZBII Sicherheitscode"}
                    value={value.securityCode}
                    onChange={v => {
                        onChange({...value, securityCode: v})
                    }}
                    invalid={invalidZBII}
                    required={true}
                    disabled={dialogStore.primaryExecuting}
                    maxLength={12}
                    help={!value.securityCode ? 'Pflichtfeld' : `B gefolgt von 11 Zahlen-Buchstabenkombination endend mit X oder Zahl`}
                />
            </div>
            <div className={"column is-half"}>
                <FieldInput
                    label={"ZBI Sicherheitscode"}
                    value={value.verificationCode}
                    onChange={v => {
                        onChange({...value, verificationCode: v})
                    }}
                    invalid={invalidZBI}
                    required={true}
                    disabled={dialogStore.primaryExecuting}
                    maxLength={7}
                    help={!value.verificationCode ? 'Pflichtfeld' : `7-stellige Zahlen-Buchstaben-Kombination`}
                />
            </div>
        </div>
    </>
})

export {
    ConfirmationFormWz
}
