import {observer} from "mobx-react";
import {CitySelect, FieldInput, FieldSelect, RegistrationOfficePortalAvailabilityIndicator} from "../../../../ui";
import React from "react";
import {deliveryAddressGender, HolderDto, HolderDtoGenderEnum} from "../../../../../api";
import {FieldPhone, PhoneNumber} from "../../../../ui/FieldPhone";
import {registrationOfficePortalAvailabilityStore, registrationStore} from "../../../../../store";
import {FieldDate} from "../../../../ui/FieldDate";
import {registrationValidationStore} from "../../../../../store/RegistrationValidationStore";


const titleOptions = Object.entries(HolderDtoGenderEnum).map((o) => {
    return {
        value: o[1],
        label: deliveryAddressGender(o[1])
    }
})

const HolderNaturalForm = observer(function HolderNaturalForm() {
    const holder = registrationStore.holder
    const disabled = registrationStore.isReadonly

    const genderHelp = registrationValidationStore.getFor("holder.gender")
    const doctorateHelp = registrationValidationStore.getFor("holder.doctorate")
    const streetHelp = registrationValidationStore.getFor("holder.street")
    const houseNumberHelp = registrationValidationStore.getFor("holder.houseNumber")
    const dateOfBirthHelp = registrationValidationStore.getFor("holder.dateOfBirth")
    const nameHelp = registrationValidationStore.getFor("holder.name")
    const nameSuffixHelp = registrationValidationStore.getFor("holder.nameSuffix")
    const cityOfBirthHelp = registrationValidationStore.getFor("holder.cityOfBirth")
    const firstNameHelp = registrationValidationStore.getFor("holder.firstName")
    const cityHelp = registrationValidationStore.getFor("holder.city")
        || registrationValidationStore.getFor("holder.postCode")

    function update(key: keyof HolderDto, val: any) {
        // @ts-ignore
        holder[key] = val
        registrationStore.holder = holder
    }

    return <>
        <div className={"columns"}>
            <div className={"column is-half"}>
                <FieldSelect disabled={disabled} invalid={Boolean(genderHelp)} help={genderHelp} value={holder.gender}
                             id={'holder-gender'}
                             onChange={v => update('gender', v)}
                             onBlur={() => registrationStore.validate()}
                             label={"Anrede"} options={titleOptions} required={true}/>
            </div>
            <div className={"column is-half"}>
                <FieldInput disabled={disabled} invalid={Boolean(doctorateHelp)} help={doctorateHelp}
                            id={'holder-doctorate'}
                            value={holder.doctorate}
                            onChange={v => update('doctorate', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Doktorgrad"}/>
            </div>
        </div>
        <div className={"columns"}>
            <div className={"column is-half"}>
                <FieldInput disabled={disabled} invalid={Boolean(firstNameHelp)} help={firstNameHelp}
                            id={'holder-first-name'}
                            value={holder.firstName}
                            onChange={v => update('firstName', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Vorname"} required={true}/>
            </div>
        </div>
        <div className={"columns"}>
            <div className={"column is-half"}>
                <FieldInput disabled={disabled} invalid={Boolean(nameHelp)} help={nameHelp} value={holder.name}
                            id={'holder-last-name'}
                            onChange={v => update('name', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Nachname"} required={true}/>
            </div>
            <div className={"column is-half"}>
                <FieldInput disabled={disabled} invalid={Boolean(nameSuffixHelp)} help={nameSuffixHelp}
                            value={holder.nameSuffix}
                            onChange={v => update('nameSuffix', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Namenszusatz"} required={false}/>
            </div>
        </div>
        <div className={"columns"}>
            <div className={"column is-half"}>
                <FieldDate disabled={disabled} invalid={Boolean(dateOfBirthHelp)} help={dateOfBirthHelp}
                           id={'holder-birthday'}
                           value={holder.dateOfBirth}
                           onChange={v => update('dateOfBirth', v)}
                           onBlur={() => registrationStore.validate()}
                           label={"Geburtsdatum"} required={true}/>
            </div>
            <div className={"column is-half"}>
                <FieldInput disabled={disabled} invalid={Boolean(cityOfBirthHelp)} help={cityOfBirthHelp}
                            id={'holder-city-of-birth'}
                            value={holder.cityOfBirth}
                            onChange={v => update('cityOfBirth', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Geburtsort"} required={true}/>
            </div>
        </div>
        <div className={"columns"}>
            <div className={"column is-half"}>
                <FieldInput disabled={disabled} value={holder.birthName}
                            onChange={v => update('birthName', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Geburtsname"} required={false}/>
            </div>
            <div className={"column is-half"}>
                <FieldInput disabled={disabled} value={holder.birthNameSuffix}
                            onChange={v => update('birthNameSuffix', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Zusatz Geb.name"} required={false}/>
            </div>
        </div>
        <div className={"columns"}>
            <div className={"column is-half"}>
                <FieldInput disabled={disabled} invalid={Boolean(streetHelp)} help={streetHelp} value={holder.street}
                            onChange={v => update('street', v)}
                            onBlur={() => registrationStore.validate()}
                            id={'holder-street'}
                            label={"Straße"} required={true}/>
            </div>
            <div className={"column is-one-quarter"}>
                <FieldInput disabled={disabled} invalid={Boolean(houseNumberHelp)} help={houseNumberHelp}
                            id={'holder-street-number'}
                            value={holder.houseNumber}
                            onChange={v => update('houseNumber', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Hausnummer"} required={true}/>
            </div>
            <div className={"column is-one-quarter"}>
                <FieldInput disabled={disabled} value={holder.addressAddition}
                            onChange={v => update('addressAddition', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Hausnummer Zusatz"} required={false}/>
            </div>
        </div>
        <div className={"columns"}>
            <div className={"column is-full"}>
                <CitySelect disabled={disabled} city={holder.city} zip={holder.postCode} required={true}
                            invalid={Boolean(cityHelp)} help={cityHelp}
                            id={'holder-zip-code'}
                            onChange={async v => {
                                if (!v) {
                                    registrationOfficePortalAvailabilityStore.reset()
                                    holder.city = undefined
                                    holder.postCode = undefined
                                } else {
                                    holder.city = v.city
                                    holder.postCode = v.zip
                                }
                                registrationStore.holder = holder
                                if (holder.postCode && holder.city) {
                                    await registrationOfficePortalAvailabilityStore.trigger(holder.postCode, holder.city)
                                }
                            }}
                            onBlur={() => registrationStore.validate()}/>

            </div>
            <div className={"column"} style={{
                display: 'block',
                zIndex: '4',
                position: 'relative',
                left: '-5.5em',
                top: '0.8em',
            }}>
                <RegistrationOfficePortalAvailabilityIndicator/>
            </div>
        </div>
        <div className={"columns"}>
            <div className={"column is-half"}>
                <FieldInput disabled={disabled} value={holder.emailAddress}
                            id={'holder-email'}
                            onChange={v => update('emailAddress', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"E-Mail"} required={false}/>
            </div>
            <div className={"column is-half"}>
                <FieldPhone disabled={disabled} value={holder.phoneNumber as PhoneNumber}
                            onChange={v => update('phoneNumber', v)}
                            onBlur={() => registrationStore.validate()}
                            label={"Telefon"} required={false}/>
            </div>
        </div>
    </>
})

export {
    HolderNaturalForm
}
