import {makeAutoObservable} from "mobx";

class ModalStore {
    private _openState: 'open' | 'closed' = 'closed'

    constructor() {
        makeAutoObservable(this)
    }

    get isOpen() {
        return this._openState === 'open'
    }

    open() {
        this._openState = 'open'
    }

    close() {
        this._openState = 'closed'
    }

}

const modalStore = new ModalStore()

export {
    modalStore,
    ModalStore
}
