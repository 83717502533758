import {observer} from "mobx-react";
import {Organization} from "../../../store";
import {OrganizationListGroup} from "./OrganizationListGroup";
import {ReactNode} from "react";

interface OrganizationListProps {
    expanded?: boolean
    itemRenderer?: (organization: Organization) => ReactNode
    elements: Organization[]
}

const OrganizationList = observer(function OrganizationList({elements, expanded, itemRenderer}: OrganizationListProps) {

    return <>{elements.map(e => {
        return <OrganizationListGroup key={e.id} organization={e} expanded={expanded} itemRenderer={itemRenderer} />
    })}</>
})

export {
    OrganizationList
}
