import {observer} from "mobx-react";
import {OrganizationPanel} from "../../OrganizationPanel";
import {ServiceTypePanel} from "../../ServiceTypePanel";
import {VehiclePanelAb} from "./VehiclePanelAb";
import {PreviousRegistrationPanelAb} from "./PreviousRegistrationPanelAb";
import {DocumentPanel} from "../../document/DocumentPanel";

const DetailAb = observer(function DetailAb() {
    return <div className="columns">
        <div className="column is-half">
            <div className="columns">
                <div className="column is-half">
                    <ServiceTypePanel />
                </div>
                <div className="column is-half">
                    <OrganizationPanel />
                </div>
            </div>
        </div>
        <div className="column is-half">
            <VehiclePanelAb />
            <PreviousRegistrationPanelAb />
            <DocumentPanel finishedOnly={true} />
        </div>
    </div>
})

export {
    DetailAb
}
