import {observer} from "mobx-react";
import {FieldInput} from "../../../ui";
import React from "react";
import {registrationStore} from "../../../../store";
import {registrationValidationStore} from "../../../../store/RegistrationValidationStore";

const InsurancePanel = observer(function InsurancePanel() {
    const evbHelp = registrationValidationStore.getFor("companyInsuranceNumber")
    return <>
        <nav className="panel">
            <p className="panel-heading">Versicherung</p>
            <div className="panel-block">
                <FieldInput
                    id={'evb'}
                    maxLength={7}
                    disabled={registrationStore.isReadonly}
                    value={registrationStore.evbNumber}
                    required={true}
                    label={"eVB Nummer"}
                    onInput={v => v.currentTarget.value = v.currentTarget.value.toUpperCase()}
                    onChange={v => registrationStore.evbNumber = v}
                    onBlur={() => registrationStore.validate()}
                    help={evbHelp}
                    invalid={Boolean(evbHelp)}
                />
            </div>
        </nav>
    </>
})

export {
    InsurancePanel
}
