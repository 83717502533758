import {observer} from "mobx-react";
import {useEffect} from "react";
import {registrationStore} from "../../../../../../store";
import {FieldInput} from "../../../../../ui";
import {
    confirmationFormTextZBI,
    ConfirmationProps,
    isValid,
    sealCodePattern,
    securityCodePattern,
    verificationCodePattern
} from "../../confirmation/ConfirmationFormCommons";

const ConfirmationFormAb = observer(function ConfirmationFormNz({
                                                                    dialogStore,
                                                                    value,
                                                                    onChange
                                                                }: ConfirmationProps) {
    const invalidZBII = !isValid(value.securityCode, securityCodePattern)
    const invalidZBI = !isValid(value.verificationCode, verificationCodePattern)
    const invalidFrontSeal = !isValid(value.frontSealCode, sealCodePattern)
    const invalidRearSeal = !isValid(value.rearSealCode, sealCodePattern)

    const certificateOfDestruction = registrationStore.certificateOfDestruction
    const hasFrontLicensePlate = registrationStore.hasFrontLicensePlate;

    useEffect(() => {
        const validSeals = hasFrontLicensePlate ?
            isValid(value.frontSealCode, sealCodePattern) && isValid(value.rearSealCode, sealCodePattern) :
            isValid(value.rearSealCode, sealCodePattern)
        const validVerificationCode = isValid(value.verificationCode, verificationCodePattern)
        if (certificateOfDestruction) {
            dialogStore.primaryReady = validVerificationCode && isValid(value.securityCode, securityCodePattern) && validSeals
        } else {
            dialogStore.primaryReady = validVerificationCode && validSeals
        }
    }, [value, dialogStore, hasFrontLicensePlate, certificateOfDestruction]);

    return <>
        <div style={{
            whiteSpace: 'pre-line'
        }}>
            {confirmationFormTextZBI}
        </div>
        <div style={{
            paddingTop: '1em',
            paddingBottom: '1em'
        }}>
            <label className={"label"}>Fahrzeug: {registrationStore.vin}</label>
        </div>
        <div className={"columns"}>
            {registrationStore.certificateOfDestruction && <div className={"column is-half"}>
                <FieldInput
                    label={"ZBII Sicherheitscode"}
                    value={value.securityCode}
                    onChange={v => {
                        onChange({...value, securityCode: v})
                    }}
                    invalid={invalidZBII}
                    required={true}
                    maxLength={12}
                    disabled={dialogStore.primaryExecuting}
                    help={!value.securityCode ? 'Pflichtfeld' : `B gefolgt von 11 Zahlen-Buchstabenkombination endend mit X oder Zahl`}
                />
            </div>}
            <div className={"column is-half"}>
                <FieldInput
                    label={"ZBI Sicherheitscode"}
                    value={value.verificationCode}
                    onChange={v => {
                        onChange({...value, verificationCode: v})
                    }}
                    invalid={invalidZBI}
                    required={true}
                    disabled={dialogStore.primaryExecuting}
                    maxLength={7}
                    help={!value.verificationCode ? 'Pflichtfeld' : `7-stellige Zahlen-Buchstaben-Kombination`}
                />
            </div>
        </div>
        <div className={"columns"}>
            {registrationStore.hasFrontLicensePlate && <div className={"column is-half"}>
                <FieldInput
                    label={"Sicherheitscode Kennzeichen vorne"}
                    value={value.frontSealCode}
                    onChange={v => {
                        onChange({...value, frontSealCode: v})
                    }}
                    invalid={invalidFrontSeal}
                    required={true}
                    disabled={dialogStore.primaryExecuting}
                    maxLength={3}
                    help={!value.frontSealCode ? 'Pflichtfeld' : `3 stellige Zahlen-Buchstabenkombination`}
                />
            </div>}
            <div className={"column is-half"}>
                <FieldInput
                    label={"Sicherheitscode Kennzeichen hinten"}
                    value={value.rearSealCode}
                    onChange={v => {
                        onChange({...value, rearSealCode: v})
                    }}
                    invalid={invalidRearSeal}
                    required={true}
                    disabled={dialogStore.primaryExecuting}
                    maxLength={3}
                    help={!value.rearSealCode ? 'Pflichtfeld' : `3 stellige Zahlen-Buchstabenkombination`}
                />
            </div>
        </div>
    </>
})

export {
    ConfirmationFormAb
}
