import {makeAutoObservable} from "mobx";
import {
    api,
    catchApi,
    CreateExportForRegistrationListParamsStatusEnum,
    CreateExportForRegistrationListParamsTypeEnum,
    RegistrationCsvExportStatusDto
} from "../api";
import {registrationOverviewStore} from "./RegistrationOverviewStore";
import {ArchiveStatus} from "./RegistrationOverviewFilterStore";


class RegistrationExportStore {

    constructor() {
        makeAutoObservable(this)
    }

    private _correlationId: string | undefined
    private _exportRunning: boolean = false

    private _exportResolved: (() => void | undefined) | undefined

    get correlationId(): string | undefined {
        return this._correlationId;
    }

    set correlationId(value: string | undefined) {
        this._correlationId = value;
    }

    get exportRunning(): boolean {
        return this._exportRunning;
    }

    set exportRunning(value: boolean) {
        this._exportRunning = value;
    }


    prepareExport(): Promise<void> {
        this.exportRunning = false;
        this._exportResolved = undefined
        const randomString = Math.random().toString(36).substring(2, 8)
        this.correlationId = `${Date.now()}${randomString}`
        return new Promise((resolve) => {
            this._exportResolved = resolve
        })
    }

    async startExport() {
        this.exportRunning = true;

        await catchApi(async () => {

            const {search} = registrationOverviewStore
            const {from, organizationId, selectedStatus, to, selectedServiceTypes} = registrationOverviewStore
            const fromIsoDateTime = from !== undefined ? new Date(from).toISOString() : undefined
            const toIsoDateTime = to !== undefined ? new Date(to).toISOString() : undefined
            const archived: boolean | undefined = this.mapArchiveStatusToApi(registrationOverviewStore.archived)
            const correlationId = this.correlationId!!

            await api.v1.createExportForRegistrationList({
                correlationId,
                search,
                status: selectedStatus as unknown as CreateExportForRegistrationListParamsStatusEnum[],
                type: selectedServiceTypes as unknown as CreateExportForRegistrationListParamsTypeEnum[],
                archived,
                organizationId,
                from: fromIsoDateTime,
                to: toIsoDateTime,
            });
        })
    }

    async downloadDocument() {
        if (this.correlationId) {
            return await catchApi(async () => {
                const result = await api.v1.getExportForRegistrationList(this.correlationId!!,{
                    format: "blob"
                })
                return await result.blob()
            })

        }
    }

    processExportStatus(status: RegistrationCsvExportStatusDto) {
        const {correlationId} = status
        if (correlationId === this.correlationId && this._exportResolved) {
            this.exportRunning = false
            this._exportResolved()
            this._exportResolved = undefined
        }
    }

    private mapArchiveStatusToApi(status: ArchiveStatus): boolean | undefined {
        switch (status) {
            case ArchiveStatus.HIDE_ARCHIVED:
                return false;
            case ArchiveStatus.SHOW_ARCHIVED:
                return undefined;
            case ArchiveStatus.SHOW_ARCHIVED_ONLY:
                return true;
        }
    }
}


const registrationExportStore = new RegistrationExportStore()
export {
    registrationExportStore
}
