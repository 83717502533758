import {WebStorageStateStore} from "oidc-client-ts";


const clientId = process.env.REACT_APP_OIDC_CLIENT_ID
const authority = process.env.REACT_APP_OIDC_AUTHORITY

const userStateStore = new WebStorageStateStore({ store: window.localStorage })

const config = {
    userStore: userStateStore,
    authority: authority,
    client_id: clientId
}

export {
    config,
    userStateStore
}
