import {observer} from "mobx-react";
import {OrganizationPanel} from "../../OrganizationPanel";
import {ServiceTypePanel} from "../../ServiceTypePanel";
import {DeliveryInfoPanel} from "../../delivery/DeliveryInfoPanel";
import {DocumentPanel} from "../../document/DocumentPanel";
import {HolderPanel} from "../../holder/HolderPanel";
import {VehiclePanelHa} from "./VehiclePanelHa";

const DetailHa = observer(function DetailHa() {
    return <div className="columns">
        <div className="column is-half">
            <div className="columns">
                <div className="column is-half">
                    <ServiceTypePanel/>
                </div>
                <div className="column is-half">
                    <OrganizationPanel/>
                </div>
            </div>

            <HolderPanel />
        </div>
        <div className="column is-half">
            <VehiclePanelHa />
            <DeliveryInfoPanel />
            <DocumentPanel />
        </div>
    </div>
})

export {
    DetailHa
}
