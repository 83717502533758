import {deliveryInformationDeliveryServiceType, DeliveryInformationDtoDeliveryServiceTypeEnum} from "../../../../../api";
import {observer} from "mobx-react";
import React, {FocusEventHandler} from "react";

interface DeliveryTypeSelectProps {
    disabled?: boolean,
    value?: DeliveryInformationDtoDeliveryServiceTypeEnum
    onChange: (value: DeliveryInformationDtoDeliveryServiceTypeEnum) => void
    onBlur?: FocusEventHandler<any> | undefined
}

const DeliveryTypeSelect = observer(function DeliveryTypeSelect({
                                                                    onChange,
                                                                    onBlur,
                                                                    disabled,
                                                                    value
                                                                }: DeliveryTypeSelectProps) {
    return <div className="field">
        <label className="label">Lieferoption</label>
        <div className="control">
            <div className={"select is-fullwidth"}>
                <select
                    disabled={disabled}
                    className={"select"}
                    value={value}
                    onBlur={onBlur}
                    onChange={e => {
                        onChange(e.target.value as DeliveryInformationDtoDeliveryServiceTypeEnum)
                    }}>
                    <option
                        value={DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_KFZ_REGISTRATION_SERVICE}>{deliveryInformationDeliveryServiceType(DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_KFZ_REGISTRATION_SERVICE)}</option>
                    <option
                        value={DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_THIRD}>{deliveryInformationDeliveryServiceType(DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_THIRD)}</option>
                    <option
                        value={DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_VEHICLE_OWNER}>{deliveryInformationDeliveryServiceType(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_VEHICLE_OWNER)}</option>
                    <option
                        value={DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD}>{deliveryInformationDeliveryServiceType(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD)}</option>
                </select>
            </div>
        </div>
    </div>
})

export {
    DeliveryTypeSelect
}
