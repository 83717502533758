import {observer} from "mobx-react";
import {SelectOption} from "../../../ui/OrganizationSelect";
import {registrationStore} from "../../../../store";
import {OrganizationDto} from "../../../../api";
import {FieldAutoComplete} from "../../../ui/FieldAutoComplete";

function map(o: OrganizationDto): SelectOption<any> {
    return {
        label: `${o.name}`,
        value: o
    }
}

const OrganizationPanel = observer(function OrganizationPanel() {
    return <nav className="panel">
        <p className="panel-heading">Standort</p>
        <div className="panel-block">
            <FieldAutoComplete
                required={true}
                disabled={registrationStore.isReadonly}
                onChange={v => {
                    registrationStore.organization = v
                }}
                onBlur={() => registrationStore.validate()}
                options={registrationStore.allOrganizations.map(map)}
                itemRenderer={v => v.label}
                value={registrationStore.organization as SelectOption<any>}
                search={async v => {
                    return await registrationStore.searchOrganization(v)
                }}
                />
        </div>
    </nav>
})

export {
    OrganizationPanel
}
