import {FieldInput} from "../../../ui";
import {registrationValidationStore} from "../../../../store/RegistrationValidationStore";
import {registrationStore} from "../../../../store";
import {observer} from "mobx-react";


const RegistrationVinField = observer(function RegistrationVinField() {
    const vinHelp = registrationValidationStore.getFor("vehicleIdentificationNumber")
    return <FieldInput
        id={'vin'}
        maxLength={17}
        disabled={registrationStore.isReadonly}
        label={"FIN"}
        onInput={v => v.currentTarget.value = v.currentTarget.value.toUpperCase()}
        required={true}
        value={registrationStore.vin}
        onChange={v => registrationStore.vin = v}
        help={vinHelp}
        onBlur={() => registrationStore.validate()}
        invalid={Boolean(vinHelp)}
    />
})

export {
    RegistrationVinField
}
