import {observer} from "mobx-react";
import {AppHeader} from "../../app";
import {DialogStore, organizationListStore, organizationStore} from "../../../store";
import {Dialog} from "../../ui";
import {Scroller} from "../../ui/Scroller";
import {OrganizationList} from "./OrganizationList";
import {useState} from "react";
import {OrganizationForm} from "./OrganizationForm";


const OrganizationOverviewPage = observer(function OrganizationOverviewPage() {
    const [dialogStore] = useState(new DialogStore())

    function openCreateDialog() {
        dialogStore.modalStore.open()
    }
    return <>
        <AppHeader title={"Standorte"}>
            <button className={"button is-primary"} onClick={() => {openCreateDialog()}}>Standort anlegen</button>
        </AppHeader>
        <Scroller>
            <OrganizationList elements={organizationListStore.tree} />
        </Scroller>
        <Dialog store={dialogStore} title={<>
            <span className="material-symbols-outlined" style={{color: "var(--bulma-primary)", marginRight: "0.25em"}}>apartment</span> Standort anlegen
        </>} primaryAction={async () => {
            await organizationStore.save()
            await organizationListStore.load()
            return true
        }} primaryActionTitle={"Speichern"}>
            <OrganizationForm />
        </Dialog>
    </>
})

export {
    OrganizationOverviewPage
}
