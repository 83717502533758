import {observer} from "mobx-react";
import "./Dialog.scss"
import {useNavigate, useParams} from "react-router-dom";

interface TabProps {
    name: string,
    title: string,
    defaultTab?: boolean,
    path: string
}

const Tab = observer(
    function Tab({name, title, defaultTab, path}: TabProps) {
        let {tab} = useParams();
        const navigate = useNavigate()
        const active = tab === name || (!tab && defaultTab)
        return <li className={active ? 'is-active' : ''}><a href={path} onClick={(e) => {
            e.preventDefault()
            navigate(path)
        }}>{title}</a></li>
    })

export {
    Tab
}
