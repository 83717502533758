import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bulma/bulma.scss';
import './index.scss';
import 'material-symbols';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "react-oidc-context";
import {oidc} from "./config";
import {AuthenticatedApp} from "./components/app";
import {baseUrl} from "./config/page";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

let pathname = window.location.pathname.replaceAll('/login_redirect', '')
const path = `/${pathname}`.replaceAll(/\/+/g, '/')

const redirect_uri = (baseUrl + path)
root.render(
    <React.StrictMode>
        <AuthProvider {...oidc.config} redirect_uri={redirect_uri}>
            <AuthenticatedApp />
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
