import {observer} from "mobx-react";
import {registrationStore} from "../../../../../../store";
import {RegistrationVehicleTypeField} from "../../../fields/RegistrationVehicleTypeField";
import {
    RegistrationCertificationOfRegistrationSerialField
} from "../../../fields/RegistrationCertificationOfRegistrationSerialField";
import {
    RegistrationCertificationOfRegistrationNumberField
} from "../../../fields/RegistrationCertificationOfRegistrationNumberField";
import {RegistrationVinField} from "../../../fields/RegistrationVinField";

const VehiclePanelAb = observer(function VehiclePanelAb() {
    return <>
        <nav className="panel">
            <p className="panel-heading">Fahrzeug</p>
            <div className="panel-block">
                <div>
                    <div className={"columns"}>
                        <div className={"column is-half"}>
                            <RegistrationVinField/>
                        </div>
                        <div className={"column is-half"}>
                            <RegistrationVehicleTypeField/>
                        </div>
                    </div>
                    <div className={"columns"}>
                        <div className={"column is-half"}>
                            <RegistrationCertificationOfRegistrationSerialField required={false}/>
                        </div>
                    </div>
                    {registrationStore.certificateOfDestruction && <div className={"columns"}>
                        <div className={"column is-half"}>
                            <RegistrationCertificationOfRegistrationNumberField required={false}/>
                        </div>
                    </div>}
                </div>
            </div>
        </nav>
    </>
})

export {
    VehiclePanelAb
}
