import {observer} from "mobx-react";
import {OrganizationPanel} from "../../OrganizationPanel";
import {ServiceTypePanel} from "../../ServiceTypePanel";
import {HolderPanel} from "../../holder/HolderPanel";
import {BankDataPanel} from "../../BankDataPanel";
import {InsurancePanel} from "../../InsurancePanel";
import {DeliveryInfoPanel} from "../../delivery/DeliveryInfoPanel";
import {DocumentPanel} from "../../document/DocumentPanel";
import {PreviousRegistrationPanelUi} from "./PreviousRegistrationPanelUi";
import {VehiclePanelUi} from "./VehiclePanelUi";

const DetailUi = observer(function DetailUi() {
    return <div className="columns">
        <div className="column is-half">
            <div className="columns">
                <div className="column is-half">
                    <ServiceTypePanel/>
                </div>
                <div className="column is-half">
                    <OrganizationPanel/>
                </div>
            </div>
            <HolderPanel />
            <BankDataPanel />
            <InsurancePanel />
        </div>
        <div className="column is-half">
            <VehiclePanelUi />
            <PreviousRegistrationPanelUi />
            <DeliveryInfoPanel />
            <DocumentPanel />
        </div>
    </div>
})

export {
    DetailUi
}
