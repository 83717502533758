import {observer} from "mobx-react";
import {ServiceTypeSelect} from "../../../ui";
import {registrationStore} from "../../../../store";

const ServiceTypePanel = observer(function ServiceTypePanel() {
    return <nav className="panel">
        <p className="panel-heading">Vorgangsart</p>
        <div className="panel-block">
            <ServiceTypeSelect
                disabled={registrationStore.isReadonly}
                value={registrationStore.serviceType}
                required={true}
                onSelect={(e) => {
                    registrationStore.serviceType = e
                }}/>
        </div>
    </nav>
})

export {
    ServiceTypePanel
}
