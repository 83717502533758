import {makeAutoObservable} from "mobx";

interface GrowlMessage {
    message: string
    action?: () => Promise<void>
    actionLabel?: string
}

class GrowlStore {
    get messages(): GrowlMessage[] {
        return this._messages;
    }
    constructor() {
        makeAutoObservable(this)
    }

    private _messages: GrowlMessage[] = []

    setMessage(message: GrowlMessage) {
        this._messages = [message]
    }

    addMessage(message: GrowlMessage) {
        this._messages = [message, ...this.messages]
    }

    remove(m: GrowlMessage) {
        this._messages = this.messages.filter(e => e !== m)
    }
}

const growlStore = new GrowlStore()

export {
    growlStore
}
