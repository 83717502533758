import {Api} from './Api'
import {api as apiConfig} from '../config'
import {makeAutoObservable} from "mobx"
import {authStore} from "../store";

export * from './Api'

interface ApiError { data: any; statusText: string }

class ApiStatus {
    constructor() {
        makeAutoObservable(this)
    }

    private num = 0
    private _apiError: ApiError | undefined

    public increment() {
        this.num++
    }

    public decrement() {
        if (this.num > 0) {
            this.num--
        }
    }

    get loading(): boolean {
        return this.num !== 0
    }

    addError(param: ApiError) {
        this._apiError = param
    }

    get error(): ApiError | undefined {
        return this._apiError
    }

    clearErrors() {
        this._apiError = undefined
    }
}

const apiStatus = new ApiStatus()

const customFetch = async (...fetchParams: Parameters<typeof fetch>) => {
    apiStatus.increment()
    if (fetchParams.length >1 && authStore.accessToken) {
        const params = fetchParams.at(1) as any
        if(params.headers) {
            params.headers['Authorization'] = `Bearer ${authStore.accessToken}`
        }
    }
    try {
        return await fetch(...fetchParams)
    } catch (e) {
        throw e
    } finally {
        apiStatus.decrement()
    }
};

const {baseUrl} = apiConfig

const api = new Api({
    customFetch: customFetch,
    baseUrl
})

export * from "./enumDisplayValues"

export async function catchApi<T>(call: () => T) {
    try {
        return await call()
    } catch (e) {
        if (e instanceof Response) {
            const response = e as Response
            const statusText = response.statusText
            if (response.status === 403) {
                apiStatus.addError({
                    statusText: `${response.status} - Zugriff verweigert`,
                    data: `Zugriff auf ${response.url} verweigert`
                })
                return
            }
            try {
                let data = await response.text()
                try {
                    data = JSON.parse(data)
                } catch (e2) {}
                apiStatus.addError({
                    statusText,
                    data
                })
            } catch (e2) {
                apiStatus.addError({
                    statusText,
                    data: e2
                })
            } finally {
                if (response.status === 401) {
                    authStore.clear()
                }
            }
        } else if (e instanceof TypeError) {
            const te = e as TypeError
            apiStatus.addError({
                statusText: te.name,
                data: te.message
            })
        } else {
            console.log('unknown error', e)
            throw e
        }
    }
}

export {
    api,
    apiStatus
}
