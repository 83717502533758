import "./LicensePlate.scss"
import {FocusEventHandler} from "react";

interface Season {
    start: number | ''
    end: number | ''
}

export interface LicensePlateData {
    distinguishingSign: string
    identification: string
    identificationNumber: string
    season?: Season
    addition?: "E" | "H"
}

export interface LicensePlateProps {
    value: LicensePlateData
    disabled?: boolean
    grayscale?: boolean
    onBlur?: FocusEventHandler<any> | undefined
    onChange?: (value: LicensePlateData) => void
}

function onInputNumber(e: React.FormEvent<HTMLInputElement>) {
    const val = e.currentTarget.value.toUpperCase().replaceAll(/[^0-9]/g, '')
    e.currentTarget.value = val
    e.currentTarget.classList.remove('l-1', 'l-2', 'l-3', 'l-4')
    e.currentTarget.classList.add(`l-${val.length}`)
}

function onInputAlpha(e: React.FormEvent<HTMLInputElement>) {
    const val = e.currentTarget.value.toUpperCase().replaceAll(/[^A-ZÄÖÜ]/g, '')
    e.currentTarget.value = val
    e.currentTarget.classList.remove('l-1', 'l-2', 'l-3', 'l-4')
    e.currentTarget.classList.add(`l-${val.length}`)
}

function lengthClass(val: string) {
    return `l-${val.length}`
}

function LicensePlate({
                          value,
                          disabled,
                          grayscale,
                          onBlur,
                          onChange
                      }: LicensePlateProps) {

    function update(key: keyof LicensePlateData, val: any) {
        const out: LicensePlateData = JSON.parse(JSON.stringify(value))
        out[key] = val
        onChange && onChange(out)
    }

    function formatSeason(input: string | number) {
        const v = `${input}`
        if (v) {
            const val = parseInt(v, 10)
            const month = val > 12 ? 12 : (val < 0 ? 1 : val)
            const out = `0${month}`
            return out.substring(out.length - 2)
        }
        return input
    }

    function onSeasonInput(e: React.FormEvent<HTMLInputElement>) {
        return formatSeason(e.currentTarget.value)
    }

    const season = value.season

    const totalMax = season ? 8 : 9

    const identificationMax = totalMax - (value.identificationNumber.length + 3)
    const identificationNumberMax = totalMax - (value.identification.length + 3)

    return <>
        <div className={`license-plate ${grayscale ? 'grayscale' : ''}`}>
            <div className={'highlight'}>
                <div className={"stars"}></div>
                <div className={"country"}>D</div>
            </div>
            <div className={"plate"}>
                <input
                    disabled={disabled}
                    value={value.distinguishingSign}
                    onInput={e => onInputAlpha(e)}
                    onBlur={onBlur}
                    onChange={e => update('distinguishingSign', e.currentTarget.value)}
                    type={"text"}
                    maxLength={3}
                    className={`distinguishing-sign ${lengthClass(value.distinguishingSign)}`}/>
                <div className={"separator"}>
                    <div className={"top"}></div>
                    <div className={"bottom"}></div>
                </div>
                <input
                    disabled={disabled}
                    value={value.identification}
                    onInput={e => onInputAlpha(e)}
                    onBlur={onBlur}
                    onChange={e => update('identification', e.currentTarget.value)}
                    type={"text"}
                    maxLength={identificationMax > 2 ? 2 : identificationMax}
                    className={`identification ${lengthClass(value.identification)}`}/>
                <input
                    disabled={disabled}
                    value={value.identificationNumber}
                    onInput={e => onInputNumber(e)}
                    onBlur={onBlur}
                    onChange={e => update('identificationNumber', e.currentTarget.value)}
                    type={"text"}
                    maxLength={identificationNumberMax > 4 ? 4 : identificationNumberMax}
                    className={`identification-number ${lengthClass(value.identificationNumber)}`}/>
            </div>
            {value.addition && <div className={"addition"}>E</div>}
            {season && <div className={"season"}>
                <div className={"start"}>
                    <input
                        disabled={disabled}
                        maxLength={3}
                        value={formatSeason(season.start)}
                        onInput={e => onSeasonInput(e)}
                        onBlur={onBlur}
                        onChange={e => {
                            const v = parseInt(e.currentTarget.value, 10) || ''
                            update('season', {
                                start: v,
                                end: season.end
                            })
                        }}/>
                </div>
                <div className={"end"}>
                    <input
                        disabled={disabled}
                        maxLength={3}
                        value={formatSeason(season.end)}
                        onInput={e => onSeasonInput(e)}
                        onBlur={onBlur}
                        onChange={e => {
                            const v = parseInt(e.currentTarget.value, 10) || ''
                            update('season', {
                                end: v,
                                start: season.start
                            })
                        }}/>
                </div>
            </div>}
        </div>
    </>
}

export {
    LicensePlate
}
