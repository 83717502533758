import {observer} from "mobx-react";
import {userListStore, userStore} from "../../../store";
import {ScrollTable} from "../../ui/ScrollTable";
import {useNavigate} from "react-router-dom";

import "./UserTable.scss"

const UserTable = observer(function UserTable() {
    const navigate = useNavigate()
    return <ScrollTable>
        <thead>
        <tr>
            <th>Kennung</th>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>E-Mail</th>
            <th></th>
        </tr>
        </thead>
        <tbody className={"user-table"}>
        {!userListStore.users.length &&
            <tr>
                <td className="empty-colum" colSpan={5}>
                    Es konnten leider keine Einträge angezeigt werden. Bitte passen Sie Ihre Suche an.
                </td>
            </tr>
        }
        {userListStore.users.map(u => {
            const iconClass = u.active ? "active" : "inactive"
            return <tr key={u.id} className={`user ${iconClass}`}>
                <td><a href={`${window.location.href}/${u.id}`} onClick={(e) => {
                    e.preventDefault()
                    navigate(u.id)
                }}>{u.username}</a></td>
                <td>{u.firstName}</td>
                <td>{u.lastName}</td>
                <td>{u.email}</td>
                <td style={{ display: 'flex', justifyContent: 'end' }}>
                    <span onClick={() => userStore.toggleActive(u)} className={`material-symbols-outlined ${iconClass}`} style={{cursor: "pointer"}}>{u.active?'toggle_on':'toggle_off'}</span>
                </td>
            </tr>
        })}
        </tbody>
    </ScrollTable>
})

export {
    UserTable
}
