import {observer} from "mobx-react";
import {registrationOfficePortalAvailabilityStore} from "../../store";

import "./RegistrationOfficePortalAvailabilityIndicator.scss"
import {RegistrationOfficePortalAvailabilityDtoStatusEnum, registrationOfficePortalAvailabilityStatus} from "../../api";

function getStatusIcon() {
    if (!registrationOfficePortalAvailabilityStore.status) {
        return
    }
    if (registrationOfficePortalAvailabilityStore.status === RegistrationOfficePortalAvailabilityDtoStatusEnum.PORTAL_FOR_REGISTRATION_OFFICE_AVAILABLE) {
        return 'check_circle'
    }
    return 'warning'
}

function getStatusColor() {
    if (!registrationOfficePortalAvailabilityStore.status) {
        return '--ct-primary-highlight-color'
    }
    if (registrationOfficePortalAvailabilityStore.status === RegistrationOfficePortalAvailabilityDtoStatusEnum.PORTAL_FOR_REGISTRATION_OFFICE_AVAILABLE) {
        return '--ct-success-color-bright'
    }
    return ' --ct-danger-color'
}

function getTitle() {
    const title = registrationOfficePortalAvailabilityStatus(registrationOfficePortalAvailabilityStore.status)
    const lastUpdate = new Date(registrationOfficePortalAvailabilityStore.lastUpdate || '')
    const dateString = isNaN(lastUpdate.valueOf()) ? undefined : lastUpdate.toLocaleString()
    return dateString ? `${title} Stand: ${dateString}` : title
}

const RegistrationOfficePortalAvailabilityIndicator = observer(function () {

    const title = getTitle()

    return <div className={"registration-office-portal-availability-indicator"}>
        {registrationOfficePortalAvailabilityStore.waiting ? <button className={"button is-loading"}></button> : (
            registrationOfficePortalAvailabilityStore.status
                ? <span title={title} style={{
                    cursor: 'help',
                    color: `var(${getStatusColor()})`
                }} className="material-symbols-outlined">{getStatusIcon()}</span>
                : <></>
        )}
    </div>
})

export {
    RegistrationOfficePortalAvailabilityIndicator
}
