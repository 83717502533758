import {observer} from "mobx-react";
import React from "react";
import {
    DeliveryInformationDtoDeliveryDocumentTypeEnum,
    DeliveryInformationDtoDeliveryServiceTypeEnum,
    RegistrationDtoServiceTypeEnum
} from "../../../../../api";
import {FieldSelect} from "../../../../ui";
import {DeliveryPickupInfoForm} from "./DeliveryPickupInfoForm";
import {DeliveryTypeSelect} from "./DeliveryTypeSelect";
import {DeliveryInformation} from "./DeliveryInformation";
import {registrationStore} from "../../../../../store";
import {registrationValidationStore} from "../../../../../store/RegistrationValidationStore";

const DeliverAllTogether = observer(function DeliverAllTogether() {
    const allOption = registrationStore.allOption
    return <>
        <DeliveryTypeSelect
            disabled={registrationStore.isReadonly}
            value={allOption}
            onChange={e => {
                registrationStore.allOption = e
            }}
            onBlur={() => registrationStore.validate()}
        />
        {allOption === DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_KFZ_REGISTRATION_SERVICE && <></>}
        {allOption === DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_THIRD
            && <DeliveryPickupInfoForm disabled={registrationStore.isReadonly} value={registrationStore.formValueForAll} onChange={val => {
                registrationStore.formValueForAll = val
            }}/>}
        {allOption === DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_VEHICLE_OWNER && <></>}
        {allOption === DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD
            && <DeliveryPickupInfoForm disabled={registrationStore.isReadonly} value={registrationStore.formValueForAll} onChange={val => {
                registrationStore.formValueForAllSendingToThird = val
            }}/>}
    </>
})

const DeliveryInfoPanel = observer(function DeliveryInfoPanel() {
    const options = [
        {
            value: DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL,
            label: 'Alle Dokumente zusammen'
        }
    ]
    const serviceType = registrationStore.serviceType
    if (serviceType === RegistrationDtoServiceTypeEnum.TZ) {
        options.push({
            value: DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_I_AND_BADGES,
            label: 'Abweichende Empfänger für: ZB I u. Siegel, ZB II'
        })
    } else if(serviceType !== RegistrationDtoServiceTypeEnum.HA) {
        options.push({
            value: DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_II,
            label: 'Abweichende Empfänger für: ZB I u. Siegel, ZB II'
        })
    }

    const deliveryInfoHelp = registrationValidationStore.hasAnyStartingWith("deliveryInformation")

    return <nav className="panel">
        <p className="panel-heading">Lieferung fertige Zulassungsunterlagen</p>
        <div className="panel-block">
            <FieldSelect
                disabled={registrationStore.isReadonly}
                label={"Zustellungsart"}
                options={options}
                value={registrationStore.primaryDeliveryType}
                help={deliveryInfoHelp ? 'Die angegebenen Daten sind fehlerhaft oder unvollständig.' : ''}
                required={true}
                invalid={deliveryInfoHelp}
                onBlur={() => registrationStore.validate()}
                onChange={e => {
                    registrationStore.primaryDeliveryType = (e as DeliveryInformationDtoDeliveryDocumentTypeEnum)
                }}/>
        </div>
        {registrationStore.primaryDeliveryType === DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL &&
            <div className={"panel-block"}>
                <div>
                    <DeliverAllTogether/>
                </div>
            </div>}
        {registrationStore.primaryDeliveryType === DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_II &&
            <div className={"panel-block"}>
                <div>
                    <DeliveryInformation/>
                </div>
            </div>}
        {registrationStore.primaryDeliveryType === DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_I_AND_BADGES &&
            <div className={"panel-block"}>
                <div>
                    <DeliveryInformation/>
                </div>
            </div>}
    </nav>
})

export {
    DeliveryInfoPanel
}
