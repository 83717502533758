import {makeAutoObservable} from "mobx";
import {api, catchApi} from "../api";

class FeatureStore {
    set features(value: Record<string, boolean>) {
        this._features = value;
    }
    get features(): Record<string, boolean> {
        return this._features;
    }


    private _features: Record<string, boolean> = {}
    constructor() {
        makeAutoObservable(this)
    }

    async load() {
        catchApi(async () => {
            const response = await api.v1.getFeatures()
            this.features = response.data
        })
    }

    isActive(feature: string): boolean {
        return this._features[feature] || false
    }
}

const featureStore = new FeatureStore()

export {
    featureStore
}
