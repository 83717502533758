import {makeAutoObservable} from "mobx";
import {api, catchApi, OrganizationDto} from "../api";

export interface Organization {
    id: string,
    data: OrganizationDto
    parent?: Organization
    children: Organization[]
}

class OrganizationListStore {
    set tree(value: Organization[]) {
        this._tree = value;
    }

    get tree(): Organization[] {
        return this._tree
    }

    private _tree: Organization[] = [];

    constructor() {
        makeAutoObservable(this)
    }

    async load() {
        await catchApi(async () => {
            const response = await api.v1.listOrganizations()
            const data = response.data
            const all = data.map(e => {
                const out: Organization = {
                    id: e.id,
                    children: [],
                    data: e
                }
                return out
            })

            const roots = this.getFor(all)
            this.buildFor(all, roots)

            this.tree = roots
        })
    }

    buildFor(all: Organization[], parents: Organization[]) {
        parents.forEach(e => {
            e.children = this.getFor(all, e)
            if (e.children && e.children.length > 0) {
                this.buildFor(all, e.children)
            }
        })
    }

    getFor(all: Organization[], parent?: Organization): Organization[] {
        if (!parent) {
            return all.filter(e => !e.data.parent)
        }
        return all.filter(e => e.data.parent === parent.id)
    }
}

const organizationListStore = new OrganizationListStore()

export {
    organizationListStore
}
