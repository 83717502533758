import {observer} from "mobx-react";
import {deliveryInformationDeliveryServiceType, DeliveryInformationDtoDeliveryServiceTypeEnum} from "../../../../../api";
import React from "react";
import {FieldSelect} from "../../../../ui";
import {DeliveryPickupInfoForm} from "./DeliveryPickupInfoForm";
import {registrationStore} from "../../../../../store";


const DeliveryInformation = observer(function SplitDelivery() {
    const options = [{
        label: deliveryInformationDeliveryServiceType(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_VEHICLE_OWNER),
        value: DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_VEHICLE_OWNER
    }, {
        label: deliveryInformationDeliveryServiceType(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD),
        value: DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD
    }]

    const zbIIOption = registrationStore.zbIIOption

    return <>
        <div>
            <label className={"label is-small"}>ZB I u. Siegel</label>
            <FieldSelect
                disabled={registrationStore.isReadonly}
                label={"Lieferoption"}
                options={options}
                value={registrationStore.zbIOption}
                onChange={v => {
                    registrationStore.zbIOption = v as DeliveryInformationDtoDeliveryServiceTypeEnum
                }}
                onBlur={() => registrationStore.validate()}
            />
            {registrationStore.zbIOption === DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD
                && <DeliveryPickupInfoForm disabled={registrationStore.isReadonly} value={registrationStore.formValueForZBI} onChange={val => {
                    registrationStore.formValueForZBI = val
                }}/>}
        </div>




        <div style={{paddingTop: '1em'}}>
            <label className={"label is-small"}>ZB II</label>
            <FieldSelect
                disabled={registrationStore.zbIOption === DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_VEHICLE_OWNER || registrationStore.isReadonly}
                label={"Lieferoption"}
                options={options}
                value={zbIIOption}
                onChange={v => {
                    registrationStore.zbIIOption = v as DeliveryInformationDtoDeliveryServiceTypeEnum
                }}
                onBlur={() => registrationStore.validate()}
            />
            <div style={{paddingTop: '0.5em'}}></div>
            {zbIIOption === DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD &&
                <DeliveryPickupInfoForm disabled={registrationStore.isReadonly} value={registrationStore.formValueForZBII} onChange={val => {
                    registrationStore.formValueForZBII = val
                }}/>}
        </div>
    </>
})

export {
    DeliveryInformation
}
