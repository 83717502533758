import {observer} from "mobx-react";
import "./Navbar.scss"
import {Link} from "react-router-dom";
import {authStore, featureStore, tenantSelectStore} from "../../store";
import {apiStatus} from "../../api";

/*
http://sso:11111/realms/carTRUST/account/?referrer=carTRUST-frontend&referrer_uri=http%3A%2F%2Flocalhost%3A3000#/
 */
function DropDownButton({
                            onClick,
                            label,
                            icon,
                            color
                        }: {
    onClick: () => void,
    label: string
    icon: string,
    color: string
}) {
    return <button
        className="dropdown-item button is-danger"
        onClick={onClick}
        style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.5em 1em',
            minWidth: '8em'
        }}
    >
        <span style={{paddingRight: '0.5em'}}>{label}</span>
        <span
            className="material-symbols-outlined"
            style={{
                color: `var(${color})`
            }}
        >{icon}</span>
    </button>
}

const UserButton = observer(function () {
    return <>
        <div className="dropdown is-right is-hoverable">
            <div className="dropdown-trigger">
                <button aria-haspopup="true" title={authStore.name} aria-controls="dropdown-menu-user" style={{
                    display: "flex",
                    width: '4em',
                    justifyContent: 'end',
                    alignItems: 'center',
                    color: '#000',
                    filter: 'invert(22%) sepia(66%) saturate(2858%) hue-rotate(184deg) brightness(75%) contrast(102%)'
                }}>
                    {authStore.initials === 'U' ?
                        <span className="material-symbols-outlined" style={{fontSize: '2em'}}>account_circle</span>
                        :
                        <span className={'initials-text'}>{authStore.initials}</span>
                    }
                </button>
            </div>
            <div className="dropdown-menu" id="dropdown-menu-user" role="menu" style={{
                minWidth: '1em'
            }}>
                <div className="dropdown-content">
                    <DropDownButton
                        onClick={() => {
                            const accountAddress = authStore.accountAddress
                            if (accountAddress) {
                                window.location.href = accountAddress
                            }
                        }}
                        label={"Einstellungen"}
                        color={"--ct-primary-blue-color"}
                        icon={"settings"}

                    />
                    <hr className="dropdown-divider"/>
                    <DropDownButton
                        onClick={() => {
                            authStore.logout()
                        }}
                        label={"Log out"}
                        color={"--ct-danger-color"}
                        icon={"logout"}

                    />
                </div>
            </div>
        </div>
    </>
})

const Navbar = observer(function Navbar() {
    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link to={"/"} className={"navbar-item"}>
                    <img alt={"carTRUST"} src={"/carTRUST.png"}/>
                </Link>

                <button className="navbar-burger" aria-label="menu" aria-expanded="false"
                        data-target="navbarBasic">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </button>
            </div>

            <div id="navbarBasic" className="navbar-menu">
                <div className="navbar-start">
                    <div className="navbar-item">
                        <div className="buttons">
                            {featureStore.isActive('LEGACY') &&
                                <Link to={`${process.env.REACT_APP_PAGE_LEGACY_URL}/vehicleregistration`}
                                      className={"button"}>
                                    <span className="material-symbols-outlined">directions_car</span>
                                    <span>{featureStore.isActive('REGISTRATION') ? '(alt)' : 'Zulassungen'}</span></Link>}
                            {featureStore.isActive('REGISTRATION') && <Link to={`/registration`} className={"button"}>
                                <span className="material-symbols-outlined">directions_car</span>
                                <span>Vorgänge</span>
                            </Link>}
                            <Link to={`/organization/branch`} className={"button"}>
                                <span className="material-symbols-outlined">apartment</span>
                                <span>Standorte</span>
                            </Link>
                            <Link to={`/organization/user`} className={"button"}>
                                <span className="material-symbols-outlined">group</span>
                                <span>Benutzer</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <button id={"navbar-loading-indicator"} className={"button is-loading"}
                                    style={{background: 'none', border: 'none', display: apiStatus.loading ? 'flex' : 'none'}}></button>
                            {tenantSelectStore.superadmin && tenantSelectStore.tenant ? <>
                                <button className="button is-danger" onClick={async () => {
                                    await tenantSelectStore.reset()
                                }}>
                                    {tenantSelectStore.tenant} verlassen
                                </button>
                            </> : <UserButton/>}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
})

export {
    Navbar
}
