import React, {FocusEventHandler} from "react";

interface FieldInputProps {
    id?: string
    required?: boolean
    label?: string
    value?: string
    invalid?: boolean
    help?: string
    placeholder?: string
    disabled?: boolean,
    maxLength?: number,
    onChange?: (value: string) => void
    onBlur?: FocusEventHandler<any> | undefined
    onInput?: (e: React.FormEvent<HTMLInputElement>) => void
}

function FieldInput({
                        id,
                        required,
                        label,
                        value,
                        invalid,
                        help,
                        placeholder,
                        disabled,
                        onChange,
                        onBlur,
                        maxLength,
                        onInput,
                    }: FieldInputProps) {
    return <>
        <div id={id} className={`field ${required ? 'required' : ''}`}>
            {label && <label className={`label`}>{label}</label>}
            <div className="control">
                <input
                    id={id?`${id}-input`:undefined}
                    className={`input is-fullwidth ${invalid ? 'is-danger' : ''} ${required ? 'required' : ''}`}
                    type={"text"}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    maxLength={maxLength}
                    onBlur={onBlur}
                    onInput={e => onInput && onInput(e)}
                    onChange={e => onChange && onChange(e.target.value)}
                />
            </div>
            <p className={`help ${invalid ? 'is-danger' : ''}`}>{help}</p>
        </div>
    </>
}

export {
    FieldInput
}
