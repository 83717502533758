import {observer} from "mobx-react";
import React, {FocusEventHandler, useState} from "react";

import "./OrganizationSelect.scss"
import {debounce} from "../../utils";

export interface SelectOption<T> {
    label: string
    value: T
}

interface OrganizationSelectProps<T> {
    value?: SelectOption<T>,
    options?: SelectOption<T>[],
    search?: (v: string) => Promise<SelectOption<T>[]>
    onChange: (v: SelectOption<T> | undefined) => void
    onBlur?: FocusEventHandler<any> | undefined
    itemRenderer?: (v: SelectOption<T>) => React.ReactNode
}

const OrganizationSelect = observer(function OrganizationSelect({
                                                                    search,
                                                                    options,
                                                                    onChange,
                                                                    onBlur,
                                                                    itemRenderer,
                                                                    value
                                                                }: OrganizationSelectProps<any>) {
    const [active, setActive] = useState(false)

    const [itemOptions, setItemOptions] = useState<SelectOption<any>[]>([])
    const [loading, setLoading] = useState(false)

    const data = itemOptions && itemOptions.length ? itemOptions : (options || [])

    async function invokeSearch(value: string | undefined) {
        if (value) {
            if (search && value.length > 2) {
                setLoading(true)
                const result = await search(value)
                setLoading(false)
                setItemOptions(result)
            }
        } else {
            setItemOptions([])
        }
    }

    return <>
        <div className={`organization-select dropdown ${active && data.length ? 'is-active' : ''}`}>
            <div className="dropdown-trigger">
                <div className={"control has-icons-right"}>
                    {value
                        ? <>
                            <div className="input">{value.label}</div>
                            <span onBlur={onBlur} onClick={() => {
                                onChange(undefined)
                            }} className="icon is-small is-right" style={{cursor: 'pointer', pointerEvents: "all"}}>
                                <span className="material-symbols-outlined">cancel</span>
                            </span>
                        </>
                        :
                        <>
                            <input
                                className="input"
                                type="text"
                                placeholder="Standort suchen..."
                                onChange={debounce(async (e) => {
                                    await invokeSearch(e.target.value)
                                }, 500)}
                                onFocus={() => {
                                    setActive(true)
                                }}
                                onBlur={e => {
                                    setTimeout(() => {
                                        setActive(false)
                                    }, 200);
                                    onBlur && onBlur(e)
                                }}
                            />{loading ?
                            <span className="icon is-small is-right">
                            <span className={"button is-loading"} style={{border: "none"}}></span>
                        </span> : <></>}
                        </>
                    }
                </div>
            </div>
            <div className="dropdown-menu" role="menu">
                <div className="dropdown-content">
                    {data.map((o, index) => {
                        return <button key={index} className="dropdown-item" onClick={() => {
                            onChange(o)
                        }}>{itemRenderer ? itemRenderer(o) : o.label}</button>
                    })}
                </div>
            </div>
        </div>
    </>
})

export {
    OrganizationSelect
}
